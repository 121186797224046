import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import React, { useEffect, useState, useRef } from "react";
import APIConfig from "../Configuration/APIConfig";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useReactToPrint } from 'react-to-print';
import { DownloadFlightVoucher } from "../Flights/DownloadFlightVoucher";
import PageLoader from "../Layout/PageLoader";
export default function HotelBookingSuccess() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [checkIn, setCheckIn] = useState(new Date());
    const [checkOut, setCheckOut] = useState(new Date());
    const [nights, setNights] = useState(1);
    const [saveResponse, setSaveResponse] = useState({});
    const [saveSearch, setSaveSearch] = useState({});
    const [saveHotel, setSaveHotel] = useState({});
    const [hotelRoom, setHotelRoom] = useState({});
    const [guestRooms, setGuestRooms] = useState([]);
    const [loading, setLoading] = useState(true);
    const contentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => contentRef.current,
    });
    const downloadPDF = () => {
        const input = contentRef.current;

        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            const imgWidth = 210;
            const pageHeight = 297;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save('hotelbooking.pdf');
        });
    };
    useEffect(() => {
        console.log(state);
        if (state === null) {
            //navigate('/searchHotels')
        }
        else if (state.hotelbookingResponse == null) {
            //navigate('/searchHotels')
        }
        else {
            
            setSaveResponse(state.hotelbookingResponse);
            setSaveSearch(state.hotelbookingResponse.commonHotelSearchRequest)
            setSaveHotel(state.hotelbookingResponse.commonHotelDetails)
            setHotelRoom(state.hotelbookingResponse.commonHotelAllRooms)
            setGuestRooms(state.hotelbookingResponse.hotelGuestInfos)
            setCheckIn(state.hotelbookingResponse.commonHotelSearchRequest.checkInDate)
            setCheckOut(state.hotelbookingResponse.commonHotelSearchRequest.checkOutDate)
            const cinDate = new Date(state.hotelbookingResponse.commonHotelSearchRequest.checkInDate);
            const coutDate = new Date(state.hotelbookingResponse.commonHotelSearchRequest.checkOutDate);
            const diffTime = Math.abs(coutDate.getTime() - cinDate.getTime());
            const nights = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            setNights(nights);
            setLoading(false)
        }
    }, []);
    return (
        <div id="main-wrapper">
            <Header></Header>
            <div className="clearfix"></div>
            <div className="pt-3 gray-simple">
                <div className="container">
                    <div className="row">
                        {/* Breadcrumb */}
                        <div className="col-xl-12 col-lg-12 col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="#" className="text-primary">Home</a></li>
                                    <li className="breadcrumb-item"><a href="#" className="text-primary">Hotel</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Booking</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div className="gray-simple position-relative">
                <div className="container">
                    <div className="row align-items-start">
                        <div className="col-xl-12 col-lg-12 col-md-12">
                            <div className="card mb-3">
                                <div className="car-body px-xl-5 px-lg-4 py-4 px-2">
                                    <div ref={contentRef}>
                                        <div className="text-center d-flex align-items-center justify-content-center">
                                            <img src="/assets/img/logo-light.png" width={200} height={100}></img>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center flex-column text-center mb-1 mt-2">
                                            <h3 className="mb-0">Booking Confirmation!</h3>
                                        </div>
                                        <p style={{ paddingLeft: "20px" }}>Dear Guest, Following are the hotel booking details</p>
                                        <div className="d-flex align-items-center justify-content-center flex-column mb-1">
                                            <div className="card mb-2">
                                                <div className="card-body">
                                                    <div className="row align-items-center m-3">
                                                        <div className="col-xl-4 col-lg-6 col-md-6">
                                                            <span>Confirmation No : {saveResponse.bookingNo}</span>
                                                        </div>
                                                        <div className="col-xl-4 col-lg-6 col-md-6">
                                                            <span>Booking Status : {saveResponse.bookingStatus=="FAILED"?<span className="badge bg-danger">FAILED</span>:<span className="badge bg-success">CONFIRMED</span>}</span>
                                                        </div>
                                                        <div className="col-xl-4 col-lg-6 col-md-6">
                                                            <span>Destination : {saveSearch.city}, {saveSearch.country}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row align-items-center m-3">
                                                        <div className="col-xl-4 col-lg-6 col-md-6">
                                                            <span>Check-In : {moment(saveSearch.checkInDate).format('DD MMM YYYY')}</span>
                                                        </div>
                                                        <div className="col-xl-4 col-lg-6 col-md-6">
                                                            <span>Check-Out : {moment(saveSearch.checkOutDate).format('DD MMM YYYY')}</span>
                                                        </div>
                                                        <div className="col-xl-4 col-lg-6 col-md-6">
                                                            <span>NoOfNIghts : {nights}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row align-items-center m-3">
                                                        <div className="col-xl-4 col-lg-6 col-md-6">
                                                            <span>Hotel Name : {saveHotel.hotelName}</span>
                                                        </div>
                                                        <div className="col-xl-4 col-lg-6 col-md-6">
                                                            <span>Address : {saveHotel.address}, {saveHotel.cityName}, {saveHotel.countryName}</span>
                                                        </div>
                                                        <div className="col-xl-4 col-lg-6 col-md-6">
                                                            <span>Postal Code : {saveHotel.postalCode}</span>
                                                        </div>
                                                        <div className="col-xl-4 col-lg-6 col-md-6">
                                                            <span>Phone No : {saveHotel.phoneNumber}</span>
                                                        </div>
                                                    </div>
                                                    <h6>Guest Details</h6>
                                                    <div className="table-responsive-md">
                                                        <table className="table table-hover table-bordered" style={{border:"1px solid black"}}>
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-center">Guest Name</th>
                                                                    <td className="text-center">Type</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {guestRooms.length > 0 && guestRooms.map((raa, index) => (
                                                                    <React.Fragment key={index}>
                                                                        {raa.adultsFields.length > 0 && raa.adultsFields.map((ra, adultIndex) => (
                                                                            <tr key={adultIndex}>
                                                                                <td className="col-sm-3 text-center">{parseInt(adultIndex + 1)}. {ra.title}. {ra.firstName} {ra.lastName}</td>
                                                                                <td className="col-sm-3 text-center">{ra.travelerType}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </React.Fragment>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <br></br>
                                                    <h6>Room Details</h6>
                                                    <div className="table-responsive-md">
                                                        <table className="table table-hover table-bordered" style={{border:"1px solid black"}}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Room</th>
                                                                    <th className="text-center">Type</th>
                                                                    <td className="text-center">Guests</td>
                                                                    <td className="text-center">Meal Type</td>
                                                                    <td className="text-center">Price</td>
                                                                </tr>
                                                            </thead>
                                                             <tbody>
                                                                {hotelRoom.requestedHotels !=null && hotelRoom.requestedHotels.map((hotel, index) => (
                                                                    <tr key={index}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{hotel.roomType}</td>
                                                                        <td>{hotel.adults} Adults, {hotel.kids} Kids</td>
                                                                        <td>{hotel.mealType}</td>
                                                                        <td>INR {Math.round(hotel.commonRoomPrice.price)}</td>
                                                                    </tr>
                                                                ))}
                                                                <tr>
                                                                    <td colSpan={4}></td>
                                                                    <td>Total Price: INR {Math.round(hotelRoom.totalPrice)}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center d-flex align-items-center justify-content-center">
                                        <button onClick={downloadPDF} className="btn btn-md btn-light-primary fw-semibold mx-2">Download Voucher</button>
                                        <button onClick={handlePrint} className="btn btn-md btn-light-primary fw-semibold mx-2">Print Voucher</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <DownloadFlightVoucher ref={contentRef} flightBookingId={params.flightBookingId}></DownloadFlightVoucher> */}
        </div>
    )
}