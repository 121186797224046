import React from 'react';
import { Link } from 'react-router-dom';

const TopHeader = () => {
    return (
        <div className="small-header">
            <div className="left-content">
                <div className="header-socials">
                    <ul>
                        <li><Link to={"https://facebook.com/flywithgrr"} target="_blank" style={{ color: "#fff" }}><i className="fa-brands fa-facebook" /></Link></li>
                        <li><Link to={"https://twitter.com/flywithgrr"} target="_blank" style={{ color: "#fff" }}><i className="fa-brands fa-twitter" /></Link></li>
                        <li><Link to={"https://instagram.com/flywithgrr"} target="_blank" style={{ color: "#fff" }}><i className="fa-brands fa-instagram" /></Link></li>
                    </ul>
                </div>
            </div>
            <div className="right-content">
            <div className="header-socials">
                    <ul>
                        <li>24 X 7 Customer Support</li>
                        <li><i className="fa fa-phone" /></li>
                        <li>+91 9032224596, 04045007721</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};
export default TopHeader;