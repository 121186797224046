export default function HomeContent(props) {
    return (
        <section className="border-bottom">
            <div className="container">
                <div className="row align-items-center justify-content-between g-4">
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-5">
                        <div className="featuresBox-wrap">
                            <div className="featuresBox-icons mb-3">
                                <i className="fa-solid fa-sack-dollar fs-1 text-primary" />
                            </div>
                            <div className="featuresBox-captions">
                                <h4 className="fw-bold fs-5 lh-base mb-0">Easy Booking</h4>
                                <p className="m-0">GRR simplifies the travel planning process, offering a seamless platform to effortlessly organize your next adventure.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-5">
                        <div className="featuresBox-wrap">
                            <div className="featuresBox-icons mb-3">
                                <i className="fa-solid fa-umbrella-beach fs-1 text-primary" />
                            </div>
                            <div className="featuresBox-captions">
                                <h4 className="fw-bold fs-5 lh-base mb-0">Best Destinations</h4>
                                <p className="m-0">From iconic landmarks to hidden gems, our curated selection of destinations promises unforgettable adventures.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-5">
                        <div className="featuresBox-wrap">
                            <div className="featuresBox-icons mb-3">
                                <i className="fa-solid fa-person-walking-luggage fs-1 text-primary" />
                            </div>
                            <div className="featuresBox-captions">
                                <h4 className="fw-bold fs-5 lh-base mb-0">Travel Guides</h4>
                                <p className="m-0">Packed with insider tips, hidden gems, and expert advice, our guides are your ultimate companion to unlocking the essence of each destination</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-5">
                        <div className="featuresBox-wrap">
                            <div className="featuresBox-icons mb-3">
                                <i className="fa-solid fa-headset fs-1 text-primary" />
                            </div>
                            <div className="featuresBox-captions">
                                <h4 className="fw-bold fs-5 lh-base mb-0">Friendly Support</h4>
                                <p className="m-0">At your service around the clock, our team is dedicated to ensuring your journey is smooth and stress-free.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}