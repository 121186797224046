import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import "react-datepicker/dist/react-datepicker.css";
import APIConfig from "../Configuration/APIConfig";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import Agenttab from "./UserTab";
import AgentMobileTab from "./UserMobileTab";
import Header from "../Layout/Header";
export default function HotelBookings() {
    const navigate = useNavigate();
    const [hotels, setHotels] = useState([]);
    const [segments, setSegments] = useState([]);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const GetAgentHotels = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETUSERHOTELS + "?Id=" + localStorage.getItem('userId'), { ...headerconfig })
            .then((response) => {
                if (response.data.response.succeeded === true) {
                    setHotels(response.data.response.data);
                }
            });
    };
    useEffect(() => {
        if (localStorage.getItem('userId') === null) {
            localStorage.clear();
            navigate("/");
        }
        else {
            GetAgentHotels();
        }
    }, [])
    return (
        <div id="main-wrapper">
            <div>
                <Header></Header>
                <div className="clearfix"></div>
                <div className="dashboard-menus border-top d-none d-lg-block" style={{ backgroundColor: 'cadetblue' }}>
                    <div className="container">
                        <Agenttab></Agenttab>
                    </div>
                </div>
                <div className="py-2 gray-simple position-relative">
                    <div className="container">
                        {/* Search Form */}
                        <div className="row justify-content-center align-items-center">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item"><a href="#" className="text-primary">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Hotel Bookings</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="gray-simple position-relative">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-xl-12 col-lg-12 col-md-12 mb-1">
                                <button className="btn btn-dark fw-medium full-width d-block d-lg-none" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDashboard" aria-controls="offcanvasDashboard"><i className="fa-solid fa-gauge me-2" />Dashboard
                                    Navigation</button>
                                <AgentMobileTab></AgentMobileTab>
                            </div>
                        </div>
                        <div className="row align-items-start justify-content-between gx-xl-4">
                            <div className="col-xl-12 col-lg-8 col-md-12">
                                {/* Personal Information */}
                                <div className="card">
                                    <div className="card-header">
                                        <h4><i className="fa-solid fa-ticket me-2" />Hotel Bookings</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>Booking No</th>
                                                        <th>Destination</th>
                                                        <th>Check-In</th>
                                                        <th>Check-Out</th>
                                                        <th>Guests</th>
                                                        <th>Rooms</th>
                                                        <th>Guest</th>
                                                        <th>Price</th>
                                                        <th>Booking Status</th>
                                                        <th>Payment Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {hotels.length > 0 && hotels.map((v, index) =>
                                                        <tr key={v.hotelBookingId}>
                                                            <td>{v.bookingNo}</td>
                                                            <td>{v.city},{v.country}</td>
                                                            <td>{moment(v.checkIn).format('DD MMM YYYY')}</td>
                                                            <td>{moment(v.checkOut).format('DD MMM YYYY')}</td>
                                                            <td>{v.noOfGuests}</td>
                                                            <td>{v.noOfRooms}</td>
                                                            <td>{v.travellerName}</td>
                                                            <td>{Math.round(v.totalPrice)} INR</td>
                                                            <td>{v.bookingStatus === "CONFIRMED" ? <span className="badge bg-success">CONFIRMED</span> : <span className="badge bg-danger">{v.bookingStatus}</span>}</td>
                                                            <td>{v.paymentStatus === "SUCCESS" ? <span className="badge bg-success">{v.paymentStatus}</span> : <span className="badge bg-danger">{v.bookingStatus}</span>}</td>
                                                            <td>
                                                                <ul className="list-inline hstack gap-2 mb-0">
                                                                    <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="View">
                                                                        <Link to={"/hotel-bookings-details/" + v.hotelBookingId} className="btn btn-md btn-primary fw-medium mb-0">View</Link>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </div>
        </div>
    )
}