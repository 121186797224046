import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import AgentHeader from "../Layout/Header";
import APIConfig from "../Configuration/APIConfig";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import PageLoader from "../Layout/PageLoader";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
const initialFieldValues = {
    studyEnquiryId: "00000000-0000-0000-0000-000000000000",
    studyDestinationId: "00000000-0000-0000-0000-000000000000",
    customerName: "",
    email: "",
    mobile: "",
    status: "NEW",
    studyDate: new Date(),
    comments: ""
};
export default function StudyDetails() {
    const { state } = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const [study, setStudy] = useState({});
    const [content, setContent] = useState([]);
    const [values, setValues] = useState(initialFieldValues);
    const [btnSubmit, setBtnSubmit] = useState(true);
    const [status, setStatus] = useState("");
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const [fromDate, setFromDate] = useState(new Date());
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const validate = () => {
        let temp = {};
        temp.customerName = values.customerName === "" ? false : true;
        temp.mobile = values.mobile === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setBtnSubmit(false);
            const formData = new FormData()
            formData.append("studyEnquiryId", "00000000-0000-0000-0000-000000000000")
            formData.append("studyDestinationId", values.studyDestinationId)
            formData.append("customerName", values.customerName)
            formData.append("email", values.email)
            formData.append("mobile", values.mobile)
            formData.append("studyDate", moment(values.studyDate).format('YYYY-MM-DD'))
            formData.append("comments", values.comments)
            addOrEdit(formData);
        }
    };
    const applicationAPI = () => {
        return {
            create: (newrecord) => axios.post(APIConfig.APIACTIVATEURL + APIConfig.CREATESTUDYENQUIRY, newrecord, { ...headerconfig })
        };
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setErrors({});
    };
    const addOrEdit = (formData) => {
        if (formData.get('studyEnquiryId') === "00000000-0000-0000-0000-000000000000") {
            applicationAPI()
                .create(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        setStatus("Enquiry Submitted Successfully");
                        resetForm();
                        setBtnSubmit(true);
                    }
                    else {
                        setStatus("");
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    setStatus("");
                    setBtnSubmit(true);
                });
        }
    }
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    const GetStudyContent = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETSTUDYCONTENTBYDESTINATIONID + "?StudyDestinationId=" + params.studyDestinationId, { ...headerconfig })
            .then((response) => {
                setStudy(response.data.data)
                setContent(response.data.data.studyContents);
                setLoading(false)
            });
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        GetStudyContent();
    }, []);
    return (
        <div id="main-wrapper">
            {loading === true ? <PageLoader></PageLoader> :
                <div>
                    <AgentHeader></AgentHeader>
                    <div className="clearfix"></div>
                    <div className="py-2 gray-simple position-relative">
                        <div className="container">
                            {/* Search Form */}
                            <div className="row justify-content-center align-items-center">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb mb-0">
                                            <li className="breadcrumb-item"><a href="#" className="text-primary">Home</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">{study.destinationName}</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="pt-3">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="card border-0 p-3 mb-4">
                                        <div className="crd-heaader d-md-flex align-items-center justify-content-between">
                                            <div className="crd-heaader-first">
                                                <div className="d-block">
                                                    <h4 className="mb-0">{study.destinationName}</h4>
                                                    <div className="exlops">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="row">
                                        {/* Details */}
                                        <div className="col-xl-9 col-lg-9 col-md-12">
                                            <div className="mb-lg-0 mb-3">
                                                <div className="relative d-flex">
                                                    <img src={study.imageSrc} style={{ width: "100%" }} alt="image" className="rounded-2 img-fluid" />
                                                </div>
                                            </div>
                                            <div className="tab-content" id="pillstour-tabContent">
                                                {/* Overview Info */}
                                                <div className="tab-pane fade show active" id="pills-overview" role="tabpanel" aria-labelledby="pills-overview-tab" tabIndex={0}>
                                                    <div className="overview-wrap full-width">
                                                        {study.description !== "undefined" ?
                                                            <div className="card mb-4 border rounded-3">
                                                                <div className="card-header">
                                                                    <h4 className="fs-5">Overview</h4>
                                                                </div>
                                                                <div className="card-body">
                                                                    <p className="mb-0">{study.content}</p>
                                                                </div>
                                                            </div> : ""}
                                                        <div className="accordion accordion-flush" id="accordionFlushExample">
                                                            {content.length > 0 && content.map(t =>
                                                                <div className="accordion-item border">
                                                                    <h2 className="accordion-header rounded-2">
                                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-day1" aria-expanded="false" aria-controls="flush-collapseOne">
                                                                            <span className="fw-bold me-2">{t.question}</span>
                                                                        </button>
                                                                    </h2>
                                                                    <div id="flush-day1" className="accordion-collapse collapse show" data-bs-parent="#accordionFlushExample">
                                                                        <div className="accordion-body">
                                                                            <div className="exportial mb-3">
                                                                                <p className="mb-0">{t.answer}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Sidebar */}
                                        <div className="col-xl-3 col-lg-3 col-md-12">
                                            <div className="sides-block">
                                                <div className="card border rounded-3 mb-4">
                                                    <span className="btn btn-sm btn-primary full-width fw-medium text-uppercase mb-2" type="button">Send Enquiry</span>
                                                    <div className="single-card px-3 py-3">
                                                        {status !== "" ?
                                                            <div class="alert alert-info shadow-md"> <i class="fas fa-check-circle"></i> {status} </div>
                                                            : ""}
                                                        <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                                            <div className="form-floating mb-2">
                                                                <input type="text" value={values.customerName} name="customerName" onChange={handleInputChange} className={"form-control" + applyErrorClass('customerName')} placeholder="Please enter customer name" />
                                                                <label>Name</label>
                                                                {errors.customerName === false ? (<div className="validationerror">Please enter customer name </div>) : ('')}
                                                            </div>
                                                            <div className="form-floating mb-2">
                                                                <input type="text" value={values.email} name="email" onChange={handleInputChange} className={"form-control" + applyErrorClass('email')} placeholder="Please enter email" />
                                                                <label>Email</label>
                                                            </div>
                                                            <div className="form-floating mb-2">
                                                                <input type="text" value={values.mobile} name="mobile" onChange={handleInputChange} className={"form-control" + applyErrorClass('mobile')} placeholder="Please enter mobile" />
                                                                <label>Phone</label>
                                                                {errors.mobile === false ? (<div className="validationerror">Please enter mobile </div>) : ('')}
                                                            </div>
                                                            <div className="mb-2">
                                                                <label htmlFor="comments" className="form-label">Comments</label>
                                                                <input type="text" value={values.comments} name="comments" onChange={handleInputChange} className={"form-control" + applyErrorClass('comments')} placeholder="Fees, Scholarships..." />
                                                                {errors.notes === false ? (<div className="validationerror">Please enter comments </div>) : ('')}
                                                            </div>
                                                            <div className="form-group">
                                                                <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                                                    {
                                                                        btnSubmit === true ?
                                                                            <button type="submit" className="btn btn-primary w-100">{values.studyEnquiryId === "00000000-0000-0000-0000-000000000000" ? "Submit" : "Update"}</button>
                                                                            :
                                                                            <button type="submit" className="btn btn-primary w-100" disabled>Please wait...</button>
                                                                    }
                                                                    <button type="button" className="btn btn-danger w-100" onClick={resetForm}>Cancel</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer></Footer>
                </div>
            }
        </div>
    )
}