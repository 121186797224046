import React, { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import APIConfig, { APIACTIVATEURL } from "../Configuration/APIConfig";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import PageLoader from "../Layout/PageLoader";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import cities from '../Data/cities.json';
export default function HotelList() {
    const { state } = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const [hotelsData, setHotelsData] = useState({});
    const [allRoomsData, setAllRoomsData] = useState([]);
    const [roomsData, setRoomsData] = useState([]);
    const [tempHotelsData, setTempHotelsData] = useState([]);
    const [saveSearch, setSaveSearch] = useState({});
    const [loading, setLoading] = useState(true);
    const [roomsLoading, setRoomsLoading] = useState(true);
    const [userId, setUserId] = useState("00000000-0000-0000-0000-000000000000");
    const [agentId, setAgentId] = useState("00000000-0000-0000-0000-000000000000");
    const [requestedBy, setRequestedBy] = useState("CUSTOMER");
    const [ratings, setRatings] = useState([]);
    const [setCallRooms, SetCallRooms] = useState(false);
    const [areas, setAreas] = useState([]);
    const [openPanel, setOpenPanel] = useState(null);

    /*Search*/
    const [travelerDisplay, setTravelerDisplay] = useState(false);
    const [displayFrom, setDisplayFrom] = useState('DUBAI, DUBAI, UNITED ARAB EMIRATES');
    const [cityId, setCityId] = useState('147862');
    const [city, setCity] = useState('DUBAI');
    const [country, setCountry] = useState('UNITED ARAB EMIRATES');
    const [totalGuests, setTotalGuests] = useState(2);
    const [Dlocations, setDLocations] = useState([]);
    const [checkInDate, setCheckInDate] = useState(new Date());
    const [checkOutDate, setCheckOutDate] = useState(new Date());
    const [totalAdults, setTotalAdults] = useState(1);
    const [totalKids, setTotalKids] = useState(1);
    const [totalRooms, setTotalRooms] = useState(1);
    const [errors, setErrors] = useState({});
    const getDLocation = async (location) => {
        const response = await axios.get(APIACTIVATEURL + 'City/search?searchkey=' + location);
        if (response.data.data !== null) {
            setDLocations(response.data.data)
        }
    }
    const handleCityLocationChange = (e) => {
        const value = e.target.value
        if (value.trim().length > 2) {
            getDLocation(value)
        }
        setDisplayFrom(value);
    }
    const selectdepartureLocationHandle = (data) => {
        var DF = data.fullName;
        setDisplayFrom(DF);
        setCityId(data.id)
        setCity(data.cityName)
        setCountry(data.countryName)
        setDLocations('')
    }
    const validate = () => {
        let temp = {};
        temp.displayFrom = displayFrom === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSearch = (e) => {
        e.preventDefault();
        if (validate()) {
            const searchQuery = {
                "cityCode": cityId,
                "city": city,
                "checkInDate": moment(checkInDate).format('YYYY-MM-DD'),
                "checkOutDate": moment(checkOutDate).format('YYYY-MM-DD'),
                "nationality": "106",
                "currency": "INR",
                "country": country,
                "rating": [2, 3, 4, 5],
                "hotelRooms": roomInfo,
                "requestedBy": "CUSTOMER",
                "totalGuests": totalGuests,
                "totalAdults": totalAdults,
                "totalKids": totalKids,
                "totalRooms": totalRooms,
                "userId": "00000000-0000-0000-0000-000000000000"
            }
            //navigate('/hotelslist/', { state: { searchQuery }, replace: true });
            BindPageData(searchQuery)
        }
    }
    const handleDisplayClick = () => {
        setTravelerDisplay(travelerDisplay => !travelerDisplay);
    }
    const handleIncreaseAdults = (e, index, adultvalue) => {
        const value = parseInt(adultvalue + 1);
        const updatedRoomInfo = [...roomInfo];
        updatedRoomInfo[index].adults = value;
        const avalues = [];
        for (var i = 0; i < value; i++) {
            avalues.push({ title: "Mr", firstName: "", lastName: "", travelerType: "ADULT", dateOfBirth: new Date(), panCard: "ABCDE1234F", passportNo: "", passportNationality: "", passbookIssueDate: "", passbookExpireDate: "" });
            updatedRoomInfo[index].adultsFields = avalues;
        }
        setTotalAdults(parseInt(totalAdults + 1))
        setTotalGuests(parseInt(totalGuests + 1))
        setRoomInfo(updatedRoomInfo);
    }
    const handleDecreaseAdults = (e, index, adultvalue) => {
        if (adultvalue !== 1) {
            const value = parseInt(adultvalue - 1);
            const updatedRoomInfo = [...roomInfo];
            updatedRoomInfo[index].adults = value;
            const avalues = [];
            for (var i = 0; i < value; i++) {
                avalues.push({ title: "Mr", firstName: "", lastName: "", travelerType: "ADULT", dateOfBirth: new Date(), panCard: "ABCDE1234F", passportNo: "", passportNationality: "", passbookIssueDate: "", passbookExpireDate: "" });
                updatedRoomInfo[index].adultsFields = avalues;
            }
            setRoomInfo(updatedRoomInfo);
            setTotalAdults(parseInt(totalAdults - 1))
            setTotalGuests(parseInt(totalGuests - 1))
        }
    }
    const handleIncreaseKids = (e, index, kidvalue) => {
        const value = parseInt(kidvalue + 1);
        const updatedRoomInfo = [...roomInfo];
        updatedRoomInfo[index].kids = value;
        updatedRoomInfo[index].kidsAge = new Array(value).fill(1);
        const avalues = [];
        for (var i = 0; i < value; i++) {
            avalues.push({ title: "Miss", firstName: "", lastName: "", travelerType: "CHILD", dateOfBirth: new Date(), panCard: "ABCDE1234F", passportNo: "", passportNationality: "", passbookIssueDate: "", passbookExpireDate: "" });
            updatedRoomInfo[index].kidsFields = avalues;
        }
        setRoomInfo(updatedRoomInfo);
        setTotalKids(parseInt(totalKids + 1))
        setTotalGuests(parseInt(totalGuests + 1))
    }
    const handleDecreaseKids = (e, index, kidvalue) => {
        if (kidvalue !== 0) {
            const value = parseInt(kidvalue - 1);
            const updatedRoomInfo = [...roomInfo];
            updatedRoomInfo[index].kids = value;
            updatedRoomInfo[index].kidsAge = new Array(value).fill(1);
            const avalues = [];
            for (var i = 0; i < value; i++) {
                avalues.push({ title: "Miss", firstName: "", lastName: "", travelerType: "CHILD", dateOfBirth: new Date(), panCard: "ABCDE1234F", passportNo: "", passportNationality: "", passbookIssueDate: "", passbookExpireDate: "" });
                updatedRoomInfo[index].kidsFields = avalues;
            }
            if (value === 0) {
                updatedRoomInfo[index].kidsFields = avalues;
            }
            setRoomInfo(updatedRoomInfo);
            setTotalKids(parseInt(totalKids - 1))
            setTotalGuests(parseInt(totalGuests - 1))
        }
    }
    const handleCheckInDate = (date) => {
        if (date !== null) {
            setCheckInDate(date)
            addDays(date)
        }
    }
    const handleCheckOutDate = (date) => {
        if (date !== null) {
            setCheckOutDate(date)
            addDays(date)
        }
    }
    const addDays = (checkInDate) => {
        if (checkInDate === undefined) {
            checkInDate = new Date();
        }
        const newDate = new Date(checkInDate.getTime());
        setCheckOutDate(newDate.setDate(checkInDate.getDate() + 1));
    }
    const [roomInfo, setRoomInfo] = useState([
        {
            adults: 1,
            kids: 1,
            kidsAge: [2],
            adultsFields: [{ title: "Mr", firstName: "", lastName: "", travelerType: "ADULT", dateOfBirth: new Date(), panCard: "ABCDE1234F", passportNo: "", passportNationality: "", passbookIssueDate: "", passbookExpireDate: "" }],
            kidsFields: [{ title: "Miss", firstName: "", lastName: "", travelerType: "CHILD", dateOfBirth: new Date(), panCard: "ABCDE1234F", passportNo: "", passportNationality: "", passbookIssueDate: "", passbookExpireDate: "" }]
        }
    ]);
    const handleChildAgeChange = (roomIndex, childIndex, value) => {
        const updatedRoomInfo = [...roomInfo];
        updatedRoomInfo[roomIndex].kidsAge[childIndex] = value;
        setRoomInfo(updatedRoomInfo);
    };
    const addRoom = () => {
        setRoomInfo([
            ...roomInfo,
            {
                adults: 1,
                kids: 1,
                kidsAge: [2],
                adultsFields: [{ title: "Mr", firstName: "", lastName: "", travelerType: "ADULT", dateOfBirth: new Date(), panCard: "ABCDE1234F", passportNo: "", passportNationality: "", passbookIssueDate: "", passbookExpireDate: "" }],
                kidsFields: [{ title: "Miss", firstName: "", lastName: "", travelerType: "CHILD", dateOfBirth: new Date(), panCard: "ABCDE1234F", passportNo: "", passportNationality: "", passbookIssueDate: "", passbookExpireDate: "" }]
            }
        ]);
        setTotalRooms(parseInt(totalRooms + 1));
        setTotalAdults(parseInt(totalAdults + 1))
        setTotalKids(parseInt(totalKids + 1))
    };
    const deleteRoom = (index) => {
        if (index !== 0) {
            const updatedRoomInfo = [...roomInfo];
            updatedRoomInfo.splice(index, 1);
            setRoomInfo(updatedRoomInfo);
            setTotalRooms(parseInt(totalRooms - 1));
            setTotalAdults(parseInt(totalAdults - 1))
            setTotalKids(parseInt(totalKids - 1))
        }
    };
    /*end search*/
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    const handleRatings = (e, value) => {
        let filteredData = tempHotelsData.filter(function (p) {
            return p.rating === value
        });
        setHotelsData(filteredData);
    }
    const handleAreas = (e, value) => {
        let filteredData = tempHotelsData.filter(function (p) {
            return p.cityName === value
        });
        setHotelsData(filteredData);
    }
    const handlePriceRange = (e, minPrice, maxPrice) => {
        const filteredData = tempHotelsData.filter(flight => {
            const totalFare = flight.commonHotelDefaultRoom?.totalPrice || 0;
            return totalFare >= minPrice && totalFare <= maxPrice;
        });
        setHotelsData(filteredData);
    }
    const GetHotels = (searchQuery) => {
        axios
            .post(APIConfig.APIACTIVATEURL + APIConfig.SEARCHHOTELS, searchQuery, { ...headerconfig })
            .then((response) => {
                var responseData = response.data.response;
                if (responseData.succeeded) {
                    setHotelsData(responseData.data.commonHotelDetails)
                    setTempHotelsData(responseData.data.commonHotelDetails)
                    var ddata = responseData.data.commonHotelDetails;
                    setAreas([...new Set(ddata.map(h => h.cityName))].sort((a, b) => a - b));
                    setLoading(false);
                }
                else {
                    alert(responseData.message);
                    setLoading(false);
                }
            }).catch(function (error) {
                alert("No hotels found");
                setLoading(false);
            });
    };
    const GetRooms = (e, hoteldata, index) => {
        e.preventDefault();
        setOpenPanel(index === openPanel ? null : index);
        setRoomsData([]);
        setRoomsLoading(true);
        const requestData = {
            "commonHotelSearchRequest": saveSearch,
            "commonHotelDetails": hoteldata
        }
        GetHotelRooms(requestData)
    };
    const handleBook = (e, hotelroom, hotel) => {
        e.preventDefault();
        const requestData = {
            "commonHotelSearchRequest": saveSearch,
            "commonHotelDetails": hotel,
            "commonHotelAllRooms": hotelroom
        }
        navigate('/hotelguests/', { state: { requestData } });
    };
    const GetHotelRooms = (requestData) => {
        setRoomsData([]);
        axios
            .post(APIConfig.APIACTIVATEURL + APIConfig.HOTELDETAILS, requestData, { ...headerconfig })
            .then((response) => {
                var responseData = response.data.response;
                if (responseData.succeeded) {
                    console.log(responseData.data.commonHotelDetails.commonHotelAllRooms)
                    setRoomsData(responseData.data.commonHotelDetails.commonHotelAllRooms)
                    setRoomsLoading(false);
                }
                else {
                    setRoomsLoading(false);
                }
            }).catch(function (error) {
                setRoomsLoading(false);
            });
    };
    const ClearFilter = () => {
        setHotelsData(tempHotelsData)
    }
    const BindPageData = (searchQuery) => {
        setLoading(true)
        setSaveSearch(searchQuery)
        GetHotels(searchQuery)
        setDisplayFrom(searchQuery.displayFrom)
        setCheckInDate(searchQuery.checkInDate)
        setCheckOutDate(searchQuery.checkOutDate)
        setRoomInfo(searchQuery.hotelRooms)
        setTotalAdults(searchQuery.totalAdults)
        setTotalKids(searchQuery.totalKids)
        setTotalRooms(searchQuery.totalRooms)
        setTotalAdults(searchQuery.totalAdults)
        setCity(searchQuery.city)
        setCityId(searchQuery.cityCode)
        setCountry(searchQuery.country)
        setRatings(searchQuery.rating)
        setTotalGuests(searchQuery.totalGuests)
    }
    const [cityResults, setCityResults] = useState([]);
    const handleCitySearch = (e) => {
        const value = e.target.value;
        setDisplayFrom(value);
        if (value.length > 0) {
            const filteredCities = cities
                .filter(city =>
                    city.cityName.toLowerCase().includes(value.toLowerCase()) ||
                    city.fullRegionName.toLowerCase().includes(value.toLowerCase())
                )
                .slice(0, 10);
            setCityResults(filteredCities);
        } else {
            setCityResults([]);
        }
    };
    const handleCitySelect = (data) => {
        var DF = data.fullRegionName;
        setDisplayFrom(DF);
        setCityId(data.id)
        setCity(data.cityName)
        setCountry(data.countryName)
        setCityResults([]);
    }
    useEffect(() => {
        /*console.log(state.searchQuery)
        setHotelsData(hotelslist.data)
        setSaveSearch(state.searchQuery)
        setTempHotelsData(hotelslist.data)
        setAreas([...new Set(hotelslist.data.map(h => h.cityName))].sort((a, b) => a - b));
        setLoading(false)*/


        if (state === null) {
            navigate('/searchhotels')
        }
        else if (state.searchQuery === null) {
            navigate('/searchhotels')
        }
        else {
            BindPageData(state.searchQuery)
        }
    }, []);
    return (
        <div id="main-wrapper">
            {loading === true ? <PageLoader></PageLoader> :
                <div>
                    <Header></Header>
                    <div className="clearfix"></div>
                    <div className="py-3 bg-success position-relative">
                        <div className="container">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div className="searchableForm bg-white rounded-3 py-3 px-4">
                                    <div className="searchableCaps">
                                        <div className="search-wrap">
                                            <div className="tab-content pt-4">
                                                <div className="tab-pane show active" id="hotels">
                                                    <div className="row gx-lg-2 g-3">
                                                        <div className="col-xl-12 col-lg-12 col-md-12">
                                                            <div className="row gy-3 gx-lg-2 gx-3">
                                                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 position-relative">
                                                                    <div className="form-group hdd-arrow mb-0">
                                                                        <h6 className="text-dark">ENTER CITY</h6>
                                                                        <input className="form-control" type="text" placeholder="Search for an airport" value={displayFrom} onChange={handleCitySearch} />
                                                                        {errors.displayFrom === false ? (<div className="validationerror">Please select departure </div>) : ('')}
                                                                        {cityResults.length > 0 && (
                                                                            <ul className="locationListVideo" style={{ minWidth: "350px" }}>
                                                                                {cityResults.map((city) => (
                                                                                    <li className="locationListVideoItem" key={city.id} onClick={() => handleCitySelect(city)}>
                                                                                        {city.fullRegionName}
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-4 col-lg-12 col-md-12">
                                                                    <div className="row gy-3 gx-lg-2 gx-3">
                                                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 position-relative">
                                                                            <h6 className="text-dark">Check-In</h6>
                                                                            <div className="position-relative">
                                                                                <ReactDatePicker className="form-control" wrapperClassName="datePicker" name="checkInDate" selected={checkInDate} onChange={(date) => handleCheckInDate(date)} dateFormat="EEEE, dd MMM yyyy" minDate={new Date()} />
                                                                                <span className="icon-inside"><i className="far fa-calendar-alt" /></span>
                                                                                {errors.checkInDate === false ? (<div className="validationerror">Please select checkInDate </div>) : ('')}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 position-relative">
                                                                            <h6 className="text-dark">Check-Out</h6>
                                                                            <div className="position-relative">
                                                                                <ReactDatePicker className="form-control" wrapperClassName="datePicker" name="checkOutDate" selected={checkOutDate} onChange={(date) => { handleCheckOutDate(date) }} dateFormat="EEEE, dd MMM yyyy" minDate={checkInDate} />
                                                                                <span className="icon-inside"><i className="far fa-calendar-alt" /></span>
                                                                                {errors.checkOutDate === false ? (<div className="validationerror">Please select checkOutDate </div>) : ('')}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-4 col-lg-12 col-md-12">
                                                                    <div className="row gy-3 gx-lg-2 gx-3">
                                                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 position-relative">
                                                                            <h6 className="text-dark">ROOMS & GUESTS</h6>
                                                                            <div className="form-group mb-0">
                                                                                <div className="booking-form__input guests-input mixer-auto" style={{ top: "60px !important" }}>
                                                                                    <button name="guests-btn" id="guests-input-btn" className={travelerDisplay === true ? "open" : ""} onClick={handleDisplayClick}>{totalRooms + " Rooms, " + totalAdults + " Adults, " + totalKids + " Children"}</button>
                                                                                    <div className={travelerDisplay === true ? "guests-input__options open" : "guests-input__options"} id="guests-input-options">
                                                                                        {roomInfo.map((room, index) => (
                                                                                            <div key={index} className="bott-block d-block">
                                                                                                <ul class="list-group list-group-borderless" style={{ marginLeft: "-30px", marginRight: "-30px", marginTop: "-30px" }}>
                                                                                                    <li class="list-group-item d-flex justify-content-between">
                                                                                                        <span class="fw-medium mb-0">Room {index + 1}</span>
                                                                                                        <span>
                                                                                                            {index !== 0 && (<Link onClick={deleteRoom} style={{ height: "35px", fontSize: "15px" }} className="btn btn-danger">Delete Room</Link>)}
                                                                                                        </span>
                                                                                                    </li>
                                                                                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                                                                                        <span className="guests-input__ctrl minus" id="adults-subs-btn" onClick={e => handleDecreaseAdults(e, index, room.adults)}><i className="fa-solid fa-minus" /></span>
                                                                                                        <span className="guests-input__value"><span defaultValue={1} readOnly value={room.adults} name="adults"><span class="fw-medium mb-0">{room.adults} Adults</span></span><br /><span class="mb-0" style={{ fontSize: "15px" }}>(Above 12 years)</span></span>
                                                                                                        <span className="guests-input__ctrl plus" id="adults-add-btn" onClick={e => handleIncreaseAdults(e, index, room.adults)}><i className="fa-solid fa-plus" /></span>
                                                                                                    </li>
                                                                                                    <li class="list-group-item d-flex justify-content-between align-items-center">
                                                                                                        <span className="guests-input__ctrl minus" id="adults-subs-btn" onClick={e => handleDecreaseKids(e, index, room.kids)}><i className="fa-solid fa-minus" /></span>
                                                                                                        <span className="guests-input__value"><span defaultValue={1} readOnly value={room.kids} name="kids"><span class="fw-medium mb-0">{room.kids} Kids</span></span><br /><span class="mb-0" style={{ fontSize: "15px" }}>(Below 12 years)</span></span>
                                                                                                        <span className="guests-input__ctrl plus" id="adults-add-btn" onClick={e => handleIncreaseKids(e, index, room.kids)}><i className="fa-solid fa-plus" /></span>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <div className="row gx-lg-2 gx-3">
                                                                                                            {room.kidsAge.length > 0 && room.kidsAge.map((age, childIndex) => (
                                                                                                                <div className="col-xl-3" key={childIndex}>
                                                                                                                    <span>Age {childIndex + 1}</span>
                                                                                                                    <select className="form-select" value={age} onChange={(e) => handleChildAgeChange(index, childIndex, parseInt(e.target.value))}>
                                                                                                                        <option value="1">1</option>
                                                                                                                        <option value="2">2</option>
                                                                                                                        <option value="3">3</option>
                                                                                                                        <option value="4">4</option>
                                                                                                                        <option value="5">5</option>
                                                                                                                        <option value="6">6</option>
                                                                                                                        <option value="7">7</option>
                                                                                                                        <option value="8">8</option>
                                                                                                                        <option value="9">9</option>
                                                                                                                        <option value="10">10</option>
                                                                                                                        <option value="11">11</option>
                                                                                                                        <option value="12">12</option>
                                                                                                                    </select>
                                                                                                                </div>
                                                                                                            ))}
                                                                                                        </div>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        ))}
                                                                                        <div className="d-flex flex-wrap gap-2">
                                                                                            <Link onClick={addRoom} style={{ height: "35px", fontSize: "15px" }} className="btn btn-primary">Add Room</Link>
                                                                                            <Link onClick={handleDisplayClick} style={{ height: "35px", fontSize: "15px" }} className="btn btn-success">Done</Link>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-xl-6 col-lg-12 col-md-12">
                                                                            <div className="form-group mb-0" style={{ paddingTop: "25px" }}>
                                                                                <button type="button" className="btn btn-primary full-width fw-medium" onClick={handleSearch}>SEARCH<i className="fa-solid fa-search-arrow ms-2" /></button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="gray-simple">
                        <div className="container">
                            <div className="row justify-content-between gy-4 gx-xl-4 gx-lg-3 gx-md-3 gx-4">
                                {/* Sidebar */}
                                <div className="col-xl-3 col-lg-4 col-md-12">
                                    <div className="filter-searchBar bg-white rounded-3">
                                        <div className="filter-searchBar-head border-bottom">
                                            <div className="searchBar-headerBody d-flex align-items-start justify-content-between px-3 py-3">
                                                <div className="searchBar-headerfirst">
                                                    <h6 className="fw-bold fs-5 m-0">Filters</h6>
                                                    <p className="text-md text-muted m-0">Showing {hotelsData.length} Hotels</p>
                                                </div>
                                                <div className="searchBar-headerlast text-end">
                                                    <Link onClick={ClearFilter} className="text-md fw-medium text-primary active">Clear All</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-searchBar-body">
                                            {/* Popular Filters */}
                                            <div className="searchBar-single px-3 py-3 border-bottom">
                                                <div className="searchBar-single-title d-flex mb-3">
                                                    <h6 className="sidebar-subTitle fs-6 fw-medium m-0">Popular</h6>
                                                </div>
                                                <div className="searchBar-single-wrap">
                                                    {areas.length > 0 && areas.map(a =>
                                                        <ul className="row align-items-center justify-content-between p-0 gx-3 gy-2 mb-0">
                                                            <li className="col-12">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="radio" name="area" onClick={e => handleAreas(e, a)} />
                                                                    <label className="form-check-label" htmlFor="fsq">{a}</label>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    )}
                                                </div>
                                            </div>
                                            {/* Star Ratings */}
                                            <div className="searchBar-single px-3 py-3 border-bottom">
                                                <div className="searchBar-single-title d-flex mb-3">
                                                    <h6 className="sidebar-subTitle fs-6 fw-medium m-0">Star Ratings</h6>
                                                </div>
                                                <div className="searchBar-single-wrap">
                                                    <ul className="row align-items-center justify-content-between p-0 gx-3 gy-2 mb-0">
                                                        <li className="col-12">
                                                            <div className="form-check lg">
                                                                <div className="frm-slicing d-flex align-items-center">
                                                                    <div className="frm-slicing-first">
                                                                        <input className="form-check-input" type="radio" id="fivestar" name="hotelrating" onClick={e => handleRatings(e, 5)} />
                                                                        <label className="form-check-label" htmlFor="fivestar" />
                                                                    </div>
                                                                    <div className="frm-slicing-end d-flex align-items-center justify-content-between full-width ps-1">
                                                                        <div className="frms-flex d-flex align-items-center">
                                                                            <div className="frm-slicing-ico text-md">
                                                                                <i className="fa fa-star text-warning" />
                                                                                <i className="fa fa-star text-warning" />
                                                                                <i className="fa fa-star text-warning" />
                                                                                <i className="fa fa-star text-warning" />
                                                                                <i className="fa fa-star text-warning" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="col-12">
                                                            <div className="form-check lg">
                                                                <div className="frm-slicing d-flex align-items-center">
                                                                    <div className="frm-slicing-first">
                                                                        <input className="form-check-input" type="radio" id="fourstar" name="hotelrating" onClick={e => handleRatings(e, 4)} />
                                                                        <label className="form-check-label" htmlFor="fourstar" />
                                                                    </div>
                                                                    <div className="frm-slicing-end d-flex align-items-center justify-content-between full-width ps-1">
                                                                        <div className="frms-flex d-flex align-items-center">
                                                                            <div className="frm-slicing-ico text-md">
                                                                                <i className="fa fa-star text-warning" />
                                                                                <i className="fa fa-star text-warning" />
                                                                                <i className="fa fa-star text-warning" />
                                                                                <i className="fa fa-star text-warning" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="col-12">
                                                            <div className="form-check lg">
                                                                <div className="frm-slicing d-flex align-items-center">
                                                                    <div className="frm-slicing-first">
                                                                        <input className="form-check-input" type="radio" id="threestar" name="hotelrating" onClick={e => handleRatings(e, 3)} />
                                                                        <label className="form-check-label" htmlFor="threestar" />
                                                                    </div>
                                                                    <div className="frm-slicing-end d-flex align-items-center justify-content-between full-width ps-1">
                                                                        <div className="frms-flex d-flex align-items-center">
                                                                            <div className="frm-slicing-ico text-md">
                                                                                <i className="fa fa-star text-warning" />
                                                                                <i className="fa fa-star text-warning" />
                                                                                <i className="fa fa-star text-warning" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="col-12">
                                                            <div className="form-check lg">
                                                                <div className="frm-slicing d-flex align-items-center">
                                                                    <div className="frm-slicing-first">
                                                                        <input className="form-check-input" type="radio" id="twostar" name="hotelrating" onClick={e => handleRatings(e, 2)} />
                                                                        <label className="form-check-label" htmlFor="threestar" />
                                                                    </div>
                                                                    <div className="frm-slicing-end d-flex align-items-center justify-content-between full-width ps-1">
                                                                        <div className="frms-flex d-flex align-items-center">
                                                                            <div className="frm-slicing-ico text-md">
                                                                                <i className="fa fa-star text-warning" />
                                                                                <i className="fa fa-star text-warning" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            {/* Pricing */}
                                            <div className="searchBar-single px-3 py-3 border-bottom">
                                                <div className="searchBar-single-title d-flex align-items-center justify-content-between mb-3">
                                                    <h6 className="sidebar-subTitle fs-6 fw-medium m-0">Pricing Range</h6>
                                                    <Link onClick={e => handlePriceRange(e, 0, 1000000)} className="text-md fw-medium text-muted active">Reset</Link>
                                                </div>
                                                <div className="searchBar-single-wrap">
                                                    <ul className="row align-items-center justify-content-between p-0 gx-3 gy-2">
                                                        <li className="col-12">
                                                            <div className="form-check">
                                                                <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="prices" onClick={e => handlePriceRange(e, 1000, 3000)} />
                                                                <label className="form-check-label" htmlFor="prices">1000 INR to 3000 INR</label>
                                                            </div>
                                                        </li>
                                                        <li className="col-12">
                                                            <div className="form-check">
                                                                <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="prices" onClick={e => handlePriceRange(e, 3000, 6000)} />
                                                                <label className="form-check-label" htmlFor="prices">3000 INR to 6000 INR</label>
                                                            </div>
                                                        </li>
                                                        <li className="col-12">
                                                            <div className="form-check">
                                                                <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="prices" onClick={e => handlePriceRange(e, 6000, 10000)} />
                                                                <label className="form-check-label" htmlFor="prices">6000 INR to 10000 INR</label>
                                                            </div>
                                                        </li>
                                                        <li className="col-12">
                                                            <div className="form-check">
                                                                <input style={{ border: "1px solid" }} className="form-check-input" type="radio" name="prices" onClick={e => handlePriceRange(e, 10000, 1000000)} />
                                                                <label className="form-check-label" htmlFor="prices">10000+ INR</label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* All List */}
                                <div className="col-xl-9 col-lg-8 col-md-12">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-xl-4 col-lg-4 col-md-4">
                                            <h5 className="fw-bold fs-6 mb-lg-0 mb-3">Showing {hotelsData.length} Search Results</h5>
                                        </div>
                                    </div>
                                    <div className="row align-items-center g-4 mt-2">
                                        {hotelsData.length > 0 && hotelsData.map((h, index) =>
                                            <div className="col-xl-12 col-lg-12 col-12">
                                                <div className="card list-layout-block rounded-3 p-3">
                                                    <div className="row">
                                                        <div className="col-xl-2 col-lg-3 col-md">
                                                            <div className="cardImage__caps rounded-2 overflow-hidden h-100">
                                                                <img className="img-fluid h-100 object-fit" src={h.mainImage} alt="image" style={{ maxHeight: "100px", minWidth: "100px", maxWidth: "100px" }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-xl col-lg col-md">
                                                            <div className="listLayout_midCaps mt-md-0 mt-3 mb-md-0 mb-3">
                                                                <div className="d-flex align-items-center justify-content-start">
                                                                    {h.rating === 1 ?
                                                                        <div className="d-inline-block">
                                                                            <i className="fa fa-star text-warning text-xs" />
                                                                        </div>
                                                                        : h.rating === 2 ?
                                                                            <div className="d-inline-block">
                                                                                <i className="fa fa-star text-warning text-xs" />
                                                                                <i className="fa fa-star text-warning text-xs" />
                                                                            </div>
                                                                            : h.rating === 3 ?
                                                                                <div className="d-inline-block">
                                                                                    <i className="fa fa-star text-warning text-xs" />
                                                                                    <i className="fa fa-star text-warning text-xs" />
                                                                                    <i className="fa fa-star text-warning text-xs" />
                                                                                </div>
                                                                                : h.rating === 4 ?
                                                                                    <div className="d-inline-block">
                                                                                        <i className="fa fa-star text-warning text-xs" />
                                                                                        <i className="fa fa-star text-warning text-xs" />
                                                                                        <i className="fa fa-star text-warning text-xs" />
                                                                                        <i className="fa fa-star text-warning text-xs" />
                                                                                    </div>
                                                                                    :
                                                                                    <div className="d-inline-block">
                                                                                        <i className="fa fa-star text-warning text-xs" />
                                                                                        <i className="fa fa-star text-warning text-xs" />
                                                                                        <i className="fa fa-star text-warning text-xs" />
                                                                                        <i className="fa fa-star text-warning text-xs" />
                                                                                        <i className="fa fa-star text-warning text-xs" />
                                                                                    </div>
                                                                    }
                                                                </div>
                                                                <h4 className="fs-5 fw-bold mb-1">{h.hotelName}</h4>
                                                                <ul className="row gx-2 p-0 excortio">
                                                                    <li className="col-auto">
                                                                        <p className="text-muted-2 text-md">{h.address}</p>
                                                                    </li>
                                                                    <li className="col-auto">
                                                                        <p className="text-muted-2 text-md">{h.cityName},{h.countryName}</p>
                                                                    </li>
                                                                    <li className="col-auto">
                                                                        <p className="text-muted-2 text-md">{h.postalCode}</p>
                                                                    </li>
                                                                    <li className="col-auto">
                                                                        <p className="text-muted-2 text-md"><a href="#" className="text-primary">Show on Map</a></p>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-auto col-lg-auto col-md-auto text-right text-md-left d-flex align-items-start align-items-md-end flex-column">
                                                            <div className="row align-items-center justify-content-start justify-content-md-end gx-2 mb-3">
                                                                <div className="d-flex align-items-center justify-content-start justify-content-md-end">
                                                                    <div className="text-dark fw-bold fs-5">INR {Math.round(h.commonHotelDefaultRoom.totalPrice)}</div>
                                                                </div>
                                                                <div className="d-flex align-items-start align-items-md-end justify-content-start justify-content-md-end flex-column mb-2">
                                                                    <div className="text-muted-2 text-sm">Per Night</div>
                                                                </div>
                                                            </div>
                                                            <div className="position-relative mt-auto full-width">
                                                                <div className="d-flex align-items-start align-items-md-end text-start text-md-end flex-column">
                                                                    {/* <button onClick={e => GetRooms(e, h)} className="btn btn-md btn-primary full-width fw-medium px-lg-4" data-bs-toggle="collapse"
                                                                        data-bs-target={"#flush-collapse" + index} aria-expanded="false" aria-controls={"flush-collapse" + index}>Check Rooms<i className="fa-solid fa-arrow-trend-up ms-2" /></button> */}
                                                                    <button className="btn btn-md btn-primary full-width fw-medium px-lg-4"
                                                                        onClick={(e) => GetRooms(e, h, index)}
                                                                        data-bs-toggle="collapse"
                                                                        data-bs-target={"#flush-collapse" + index}
                                                                        aria-expanded={openPanel === index}
                                                                        aria-controls={"flush-collapse" + index}>Check Rooms<i className="fa-solid fa-arrow-trend-up ms-2" /></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div id={"flush-collapse" + index} className={"accordion-collapse collapse" + (openPanel === index ? " show" : "")} data-bs-parent="#accordionFlushExample">
                                                            <table className="table table-border">
                                                                <thead>
                                                                    <tr>
                                                                        <th></th>
                                                                        <th>Room Type</th>
                                                                        <th>Guests</th>
                                                                        <th>Meal Type</th>
                                                                        <th>Type</th>
                                                                        <th colSpan={2}>Room Price</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {roomsLoading ? (
                                                                        <tr>
                                                                            <td colSpan={7}><PageLoader /></td>
                                                                        </tr>
                                                                    ) : (
                                                                        roomsData.length > 0 && roomsData.map((hr, index) => (
                                                                            <React.Fragment key={index}>
                                                                                {hr.requestedHotels.map((rd, rindex) => (
                                                                                    <tr key={rindex}>
                                                                                        <td>{rindex + 1}</td>
                                                                                        <td>{rd.roomType}</td>
                                                                                        <td>{rd.adults} Adults {rd.kids} Children</td>
                                                                                        <td>{rd.mealType}</td>
                                                                                        <td>{hr.bookingType === "NONREFUNDABLE" ? <span className="badge bg-danger me-2">NON REFUNDABLE</span> : <span className="badge bg-success me-2">{rd.bookingType}</span>}</td>
                                                                                        <td colSpan={2}>INR {Math.round(rd.totalPrice)}</td>
                                                                                    </tr>
                                                                                ))}
                                                                                <tr>
                                                                                    <td colSpan={3}></td>
                                                                                    <td>Total:</td>
                                                                                    <td colSpan={2}><strong>INR {Math.round(hr.totalPrice)}</strong></td>
                                                                                    <td><button onClick={e => handleBook(e, hr, h)} className="btn btn-sm btn-primary">Book</button></td>
                                                                                </tr>
                                                                            </React.Fragment>
                                                                        ))
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <h4>No Results Found</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer></Footer>
                </div>
            }
        </div>
    )
}