import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import { useEffect, useState } from "react";
import APIConfig from "../Configuration/APIConfig";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import PageLoader from "../Layout/PageLoader";
const initialValues = {
    transactionId: "00000000-0000-0000-0000-000000000000",
    amount: 0,
    transactionType: "CREDIT",
    bookingType: "VISA",
    status: "",
    referenceNo: "",
    paymentGatewayNo: "",
    createdDate: new Date(),
    referenceId: "00000000-0000-0000-0000-000000000000"
};
export default function RoundTripReview() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const [travellers, setTravellers] = useState([]);
    const [flightBookingRequest, setFlightBookingRequest] = useState({});
    const [flightsdata, setFlightsData] = useState([])
    const [departureSegments, setDepartureSegments] = useState([])
    const [returnSegments, setReturnSegments] = useState([])
    const [flightData, setFlightData] = useState([])
    const [returnData, setReturnData] = useState([])
    const [flightPriceData, setFlightPriceData] = useState([])
    const [convenienceFee, setConvenienceFee] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [couponCode, setCouponCode] = useState('');
    const [totalOriginalPrice, setTotalOriginalPrice] = useState(0);
    const [promocodes, setPromoCodes] = useState([]);
    const [baseprice, setBasePrice] = useState(0)
    const [tax, setTax] = useState(0)
    const [totalPrice, setTotalPrice] = useState(0)
    const [totalFare, setTotalFare] = useState(0)
    const [gst, setGST] = useState(0)
    const [gstAmount, setGSTAmount] = useState(0)
    const [loading, setLoading] = useState(false);
    const [priceChange, setPriceChange] = useState('');
    const [paymentGatewayFee, setPaymentGatewayFee] = useState(0);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    const handleFlightSearch = (e) => {
        navigate('/searchflights');
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        displayRazorpay(flightBookingRequest);
    };
    function getTimeFromMins(mins) {
        var h = mins / 60 | 0, m = mins % 60 | 0;
        var durationformat = h + "h " + m + "m";
        return durationformat;
    }
    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }
    async function displayRazorpay(flightBookingRequest) {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }
        //let tamount = parseInt(totalPrice) + parseInt(paymentGatewayFee);
        //setTotalPrice(parseInt(totalPrice) + parseInt(paymentGatewayFee));
        // creating a new order
        const result = await axios.post(
            APIConfig.APIACTIVATEURL + APIConfig.PAYMENTREQUESTORDER + "?Amount=" + Math.round(totalPrice)
        );

        if (!result) {
            alert("Server error. Are you online?");
            return;
        }

        // Getting the order details back
        if (result.data.response.succeeded) {
            const { amount, id: order_id, currency } = result.data.response.data;
            const options = {
                key: APIConfig.RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
                amount: amount,
                currency: currency,
                name: APIConfig.COMPANYNAME,
                description: "Flight Booking Fee",
                order_id: order_id,
                handler: async function (response) {
                    const data = {
                        orderCreationId: order_id,
                        razorpayPaymentId: response.razorpay_payment_id,
                        razorpayOrderId: response.razorpay_order_id,
                        razorpaySignature: response.razorpay_signature,
                    };
                    initialValues.amount = totalPrice;
                    initialValues.transactionType = "Credit";
                    initialValues.referenceId = params.visaRequestId;
                    initialValues.status = "SUCCESS";
                    initialValues.bookingType = "FLIGHT";
                    initialValues.paymentGatewayNo = response.razorpay_payment_id;
                    InsertTransaction(initialValues, flightBookingRequest);
                },
                prefill: {
                    name: flightBookingRequest.travellerInfos[0].firstName + " " + flightBookingRequest.travellerInfos[0].lastName,
                    email: APIConfig.COMPANYEMAIL,
                    contact: APIConfig.COMAPNYPHONE,
                },
                notes: {
                    address: APIConfig.COMPANYCITY,
                },
                theme: {
                    color: "#61dafb",
                },
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        }
        else {
            alert("Payment failed. Please try again");
        }
    }
    const InsertTransaction = (data, flightBookingRequest) => {
        setLoading(true)
        flightBookingRequest.transactionNo = data.paymentGatewayNo;
        flightBookingRequest.paymentStatus = data.status;
        flightBookingRequest.paymentGatewayNo = data.paymentGatewayNo;
        flightBookingRequest.discount = discount;
        flightBookingRequest.totalPrice = totalPrice;
        flightBookingRequest.couponCode = couponCode;
        flightBookingRequest.paymentGatewayFee = paymentGatewayFee;
        axios
            .post(APIConfig.APIACTIVATEURL + APIConfig.BOOKFLIGHT, flightBookingRequest, { ...headerconfig })
            .then((response) => {
                var responseData = response.data.response;
                flightBookingRequest = responseData.data;
                setLoading(false)
                navigate('/roundtrip-itinerary', { state: { flightBookingRequest } })
            })
            .catch(error => {
                setLoading(false);
            });
    };
    const handleCouponCode = (e, promocode) => {
        if (promocode !== null) {
            var pcvalue = parseInt(promocode.value);
            setDiscount(promocode.value);
            setCouponCode(promocode.code);
            setTotalPrice(parseInt(totalOriginalPrice) - (parseInt(pcvalue) + parseInt(gstAmount)));
        }
    }
    const [promoCodeError, setPromoCodeError] = useState(false);
    const checkCouponCode = (e) => {
        e.preventDefault();
        if (couponCode !== null) {
            const isValuePresent = promocodes.find(item => item.code === couponCode);
            if (isValuePresent) {
                var pcvalue = parseInt(isValuePresent.value);
                setDiscount(isValuePresent.value)
                setCouponCode(isValuePresent.code)
                setTotalPrice(parseInt(totalOriginalPrice) - (parseInt(pcvalue) + parseInt(gstAmount)));
                setPromoCodeError(false)
            } else {
                setPromoCodeError(true)
            }
        }
    }
    const GetPromoCodes = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETPROMOCODES, { ...headerconfig })
            .then((response) => {
                if (response.data.data.succeeded) {
                    const pc = response.data.data.data;
                    const filteredList = pc.filter(item => item.type == params.isDomestic);
                    setPromoCodes(filteredList);
                }
            });
    };
    const GetPaymentGateway = (tamount) => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETPAYMENTGATEWAYFEE, { ...headerconfig })
            .then((response) => {
                let pgfeep = response.data.response.data;
                if (pgfeep > 0) {
                    let pgfee = parseFloat(parseFloat(pgfeep) / 100);
                    pgfee = parseFloat(pgfee) * parseFloat(tamount);
                    setPaymentGatewayFee(Math.round(pgfee));
                }
            });
    };
    useEffect(() => {
        if (state === null) {
            navigate('/searchflights')
        }
        else if (state.flightBookingRequest === null) {
            navigate('/searchflights')
        }
        else {
            setFlightsData(state.flightsdata);
            setFlightBookingRequest(state.flightBookingRequest);
            setTravellers(state.flightBookingRequest.travellerInfos);
            var depdata = state.flightsdata.filter(flight => flight.journeyType === 'ONWARD');
            setFlightData(depdata[0])
            setDepartureSegments(depdata[0].tFSegments)
            var retdata = state.flightsdata.filter(flight => flight.journeyType === 'RETURN');
            setReturnData(retdata[0])
            setReturnSegments(retdata[0].tFSegments)
            setBasePrice(state.flightsdata.reduce((sum, flight) => sum + flight.tFPriceDetails.basePrice, 0))
            setTax(state.flightsdata.reduce((sum, flight) => sum + flight.tFPriceDetails.tax, 0))
            setTotalPrice(parseInt(state.flightBookingRequest.totalPrice) + parseInt(state.flightBookingRequest.convenienceFee) + parseInt(state.flightBookingRequest.gstAmount))
            setTotalOriginalPrice(parseInt(state.flightBookingRequest.totalPrice) + parseInt(state.flightBookingRequest.convenienceFee) + parseInt(state.flightBookingRequest.gstAmount))
            setTotalFare(state.flightBookingRequest.totalFare)
            setConvenienceFee(state.flightBookingRequest.convenienceFee)
            setPaymentGatewayFee(state.flightBookingRequest.paymentGatewayFee)
            setDiscount(state.flightBookingRequest.discount)
            setGST(state.flightBookingRequest.gst)
            setGSTAmount(state.flightBookingRequest.gstAmount);
            GetPromoCodes();
            //GetPaymentGateway(parseInt(state.flightBookingRequest.totalPrice) + parseInt(state.flightBookingRequest.convenienceFee) + parseInt(state.flightBookingRequest.gstAmount));
            setFlightPriceData(state.flightsdata.tfPriceDetails);
        }
    }, []);
    return (
        <div id="main-wrapper">
            {loading === true ? <PageLoader></PageLoader> :
                <div>
                    <Header></Header>
                    <div className="clearfix"></div>
                    <section className="pt-3 gray-simple">
                        <div className="container">
                            <div className="row">
                                {/* Breadcrumb */}
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="#" className="text-primary">Home</a></li>
                                            <li className="breadcrumb-item"><a href="#" className="text-primary">Flight</a></li>

                                        </ol>
                                    </nav>
                                </div>
                                {/* Flight Info */}
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="row">
                                        <div className="col-xl-9 col-lg-8 col-md-12">
                                            <div className="bg-white shadow-md rounded p-3 p-sm-4 confirm-details">
                                                <h5 className="text-6 mb-3 mb-sm-4">Confirm Flight Details</h5>
                                                <hr className="mx-n3 mx-sm-n4 mb-4" />
                                                {departureSegments.length > 0 && departureSegments.map((seg, index) =>
                                                    <div className="card mb-4">
                                                        <div className="card-header">
                                                            <div className="row align-items-center trip-title">
                                                                <div className="col-5 col-sm-auto text-center text-sm-start">
                                                                    <h5 className="m-0 trip-place">{seg.tFDepartureData.city}, {seg.tFDepartureData.country}</h5>
                                                                </div>
                                                                <div className="col-2 col-sm-auto text-8 text-black-50 text-center trip-arrow">➝</div>
                                                                <div className="col-5 col-sm-auto text-center text-sm-start">
                                                                    <h5 className="m-0 trip-place">{seg.tFArrivalData.city}, {seg.tFArrivalData.country}</h5>
                                                                </div>
                                                                <div className="col-12 mt-1 d-block d-md-none" />
                                                                <div className="col-6 col-sm col-md-auto text-3 date">{moment(seg.tFDepartureData.departureDateTime).format('Do MMM YYYY ddd')}</div>
                                                                <div className="col-6 col-sm col-md-auto text-end order-sm-1"><a className="text-1" data-bs-toggle="modal" data-bs-target={"#fare-rules" + index} href>Fare Rules</a></div>
                                                                {/* <div className="col col-md-auto text-center ms-auto order-sm-0">{flightData[0].tFPriceDetails.tFAdults.refundable === "NonRefundable" ? <span className="badge bg-danger py-1 px-2 fw-normal text-1">NonRefundable</span> : <span className="badge bg-success py-1 px-2 fw-normal text-1">Refundable</span>}</div> */}
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-3 text-center text-md-start d-lg-flex company-info"> <span className="align-middle"><img className="img-fluid" alt src={"/assets/images/airlinelogos/" + seg.airlineCode + ".png"} /> </span> <span className="align-middle ms-lg-2"> <span className="d-block text-2 text-dark mt-1 mt-lg-0">{seg.airline}</span> <small className="text-muted d-block">{seg.equimentType + "-" + seg.flightNumber}</small> </span> </div>
                                                                <div className="col-12 col-sm-3 text-center time-info mt-3 mt-sm-0"> <span className="text-5 text-dark">{moment(seg.tFDepartureData.departureDateTime).format('hh:mm A')}</span> <small className="text-muted d-block">{seg.tFDepartureData.airportName}, {seg.tFDepartureData.city}</small> </div>
                                                                <div className="col-12 col-sm-3 text-center time-info mt-3 mt-sm-0"> <span className="text-3 text-dark">{getTimeFromMins(seg.duration)}</span> <small className="text-muted d-block">Duration</small> </div>
                                                                <div className="col-12 col-sm-3 text-center time-info mt-3 mt-sm-0"> <span className="text-5 text-dark">{moment(seg.tFArrivalData.arrivalDateTime).format('hh:mm A')}</span> <small className="text-muted d-block">{seg.tFArrivalData.airportName}, {seg.tFArrivalData.city}</small> </div>
                                                            </div>
                                                            <div id={"fare-rules" + index} className="modal fade" role="dialog" aria-hidden="true">
                                                                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title">Fare Rules</h5>
                                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <ul className="nav nav-tabs" id="departureTab" role="tablist">
                                                                                <li className="nav-item"> <a className="nav-link active" id={"second-tab" + index} data-bs-toggle="tab" href={"#second" + index} role="tab" aria-controls={"second" + index} aria-selected="false">Fare Details</a> </li>
                                                                                <li className="nav-item"> <a className="nav-link" id={"third-tab" + index} data-bs-toggle="tab" href={"#third" + index} role="tab" aria-controls={"third" + index} aria-selected="false">Baggage Details</a> </li>
                                                                                <li className="nav-item"> <a className="nav-link" id={"fourth-tab" + index} data-bs-toggle="tab" href={"#fourth" + index} role="tab" aria-controls={"fourth" + index} aria-selected="false">Cancellation Fee</a> </li>
                                                                            </ul>
                                                                            <div className="tab-content my-3" id="departureContent">
                                                                                <div className="tab-pane fade show active" id={"second" + index} role="tabpanel" aria-labelledby="second-tab">
                                                                                    <div className="table-responsive-md">
                                                                                        <table className="table table-hover table-bordered">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th className="text-center">Base Fare</th>
                                                                                                    <td className="text-center">Net Fare</td>
                                                                                                    <td className="text-center">Tax</td>
                                                                                                    <td className="text-center">Total</td>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td>Adult</td>
                                                                                                    <td className="text-center">{Math.round(flightData.tFPriceDetails.tFAdults.baseFare)}</td>
                                                                                                    <td className="text-center">{Math.round(flightData.tFPriceDetails.tFAdults.netFare)}</td>
                                                                                                    <td className="text-center">{Math.round(flightData.tFPriceDetails.tFAdults.tax)}</td>
                                                                                                    <td className="text-center">{Math.round(flightData.tFPriceDetails.tFAdults.totalFare)}</td>
                                                                                                </tr>
                                                                                                {flightData.tFPriceDetails.tFKids !== null ?
                                                                                                    <tr>
                                                                                                        <td className="text-center">{Math.round(flightData.tFPriceDetails.tFKids.baseFare)}</td>
                                                                                                        <td className="text-center">{Math.round(flightData.tFPriceDetails.tFKids.netFare)}</td>
                                                                                                        <td className="text-center">{Math.round(flightData.tFPriceDetails.tFKids.tax)}</td>
                                                                                                        <td className="text-center">{Math.round(flightData.tFPriceDetails.tFKids.totalFare)}</td>
                                                                                                    </tr>
                                                                                                    : ""
                                                                                                }
                                                                                                {flightData.tFPriceDetails.tFInfants !== null ?
                                                                                                    <tr>
                                                                                                        <td className="text-center">{Math.round(flightData.tFPriceDetails.tFInfants.baseFare)}</td>
                                                                                                        <td className="text-center">{Math.round(flightData.tFPriceDetails.tFInfants.netFare)}</td>
                                                                                                        <td className="text-center">{Math.round(flightData.tFPriceDetails.tFInfants.tax)}</td>
                                                                                                        <td className="text-center">{Math.round(flightData.tFPriceDetails.tFInfants.totalFare)}</td>
                                                                                                    </tr> : ""
                                                                                                }
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="tab-pane fade" id={"third" + index} role="tabpanel" aria-labelledby="third-tab">
                                                                                    <div className="table-responsive-md">
                                                                                        <table className="table table-hover table-bordered">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>&nbsp;</th>
                                                                                                    <td className="text-center">Cabin</td>
                                                                                                    <td className="text-center">Check-In</td>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td>Adult</td>
                                                                                                    <td className="text-center">{flightData.tFPriceDetails.tFAdults.cabinBag}</td>
                                                                                                    <td className="text-center">{flightData.tFPriceDetails.tFAdults.checkinBag}</td>
                                                                                                </tr>
                                                                                                {flightData.tFPriceDetails.tFKids !== null ?
                                                                                                    <tr>
                                                                                                        <td>Child</td>
                                                                                                        <td className="text-center">{flightData.tFPriceDetails.tFKids.cabinBag}</td>
                                                                                                        <td className="text-center">{flightData.tFPriceDetails.tFKids.checkinBag}</td>
                                                                                                    </tr>
                                                                                                    :
                                                                                                    <tr>
                                                                                                        <td>Child</td>
                                                                                                        <td className="text-center">7 Kg</td>
                                                                                                        <td className="text-center">15 Kg</td>
                                                                                                    </tr>
                                                                                                }
                                                                                                {flightData.tFPriceDetails.tFInfants !== null ?
                                                                                                    <tr>
                                                                                                        <td>Infant</td>
                                                                                                        <td className="text-center">{flightData.tFPriceDetails.tFInfants.cabinBag}</td>
                                                                                                        <td className="text-center">{flightData.tFPriceDetails.tFInfants.checkinBag}</td>
                                                                                                    </tr>
                                                                                                    :
                                                                                                    <tr>
                                                                                                        <td>Infant</td>
                                                                                                        <td className="text-center">0 Kg</td>
                                                                                                        <td className="text-center">0 Kg</td>
                                                                                                    </tr>
                                                                                                }
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="tab-pane fade" id={"fourth" + index} role="tabpanel" aria-labelledby="fourth-tab">
                                                                                    <p className="fw-bold">Terms &amp; Conditions</p>
                                                                                    <ul>
                                                                                        <li>The penalty is subject to 4 hrs before departure. No Changes are allowed after that.</li>
                                                                                        <li>The charges are per passenger per sector.</li>
                                                                                        <li>Partial cancellation is not allowed on tickets booked under special discounted fares.</li>
                                                                                        <li>In case of no-show or ticket not cancelled within the stipulated time, only statutory taxes are refundable subject to Service Fee.</li>
                                                                                        <li>No Baggage Allowance for Infants</li>
                                                                                        <li>Airline penalty needs to be reconfirmed prior to any amendments or cancellation.</li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <h5>Return</h5>
                                                {returnSegments.length > 0 && returnSegments.map((seg, index) =>
                                                    <div className="card mb-4">
                                                        <div className="card-header">
                                                            <div className="row align-items-center trip-title">
                                                                <div className="col-5 col-sm-auto text-center text-sm-start">
                                                                    <h5 className="m-0 trip-place">{seg.tFDepartureData.city}, {seg.tFDepartureData.country}</h5>
                                                                </div>
                                                                <div className="col-2 col-sm-auto text-8 text-black-50 text-center trip-arrow">➝</div>
                                                                <div className="col-5 col-sm-auto text-center text-sm-start">
                                                                    <h5 className="m-0 trip-place">{seg.tFArrivalData.city}, {seg.tFArrivalData.country}</h5>
                                                                </div>
                                                                <div className="col-12 mt-1 d-block d-md-none" />
                                                                <div className="col-6 col-sm col-md-auto text-3 date">{moment(seg.tFDepartureData.departureDateTime).format('Do MMM YYYY ddd')}</div>
                                                                <div className="col-6 col-sm col-md-auto text-end order-sm-1"><a className="text-1" data-bs-toggle="modal" data-bs-target={"#fare-rules" + index} href>Fare Rules</a></div>
                                                                {/* <div className="col col-md-auto text-center ms-auto order-sm-0">{flightData[0].tFPriceDetails.tFAdults.refundable === "NonRefundable" ? <span className="badge bg-danger py-1 px-2 fw-normal text-1">NonRefundable</span> : <span className="badge bg-success py-1 px-2 fw-normal text-1">Refundable</span>}</div> */}
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-12 col-sm-3 text-center text-md-start d-lg-flex company-info"> <span className="align-middle"><img className="img-fluid" alt src={"/assets/images/airlinelogos/" + seg.airlineCode + ".png"} /> </span> <span className="align-middle ms-lg-2"> <span className="d-block text-2 text-dark mt-1 mt-lg-0">{seg.airline}</span> <small className="text-muted d-block">{seg.equimentType + "-" + seg.flightNumber}</small> </span> </div>
                                                                <div className="col-12 col-sm-3 text-center time-info mt-3 mt-sm-0"> <span className="text-5 text-dark">{moment(seg.tFDepartureData.departureDateTime).format('hh:mm A')}</span> <small className="text-muted d-block">{seg.tFDepartureData.airportName}, {seg.tFDepartureData.city}</small> </div>
                                                                <div className="col-12 col-sm-3 text-center time-info mt-3 mt-sm-0"> <span className="text-3 text-dark">{getTimeFromMins(seg.duration)}</span> <small className="text-muted d-block">Duration</small> </div>
                                                                <div className="col-12 col-sm-3 text-center time-info mt-3 mt-sm-0"> <span className="text-5 text-dark">{moment(seg.tFArrivalData.arrivalDateTime).format('hh:mm A')}</span> <small className="text-muted d-block">{seg.tFArrivalData.airportName}, {seg.tFArrivalData.city}</small> </div>
                                                            </div>
                                                            <div id={"fare-rules" + index} className="modal fade" role="dialog" aria-hidden="true">
                                                                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title">Fare Rules</h5>
                                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <ul className="nav nav-tabs" id="departureTab" role="tablist">
                                                                                <li className="nav-item"> <a className="nav-link active" id={"second-tab" + index} data-bs-toggle="tab" href={"#second" + index} role="tab" aria-controls={"second" + index} aria-selected="false">Fare Details</a> </li>
                                                                                <li className="nav-item"> <a className="nav-link" id={"third-tab" + index} data-bs-toggle="tab" href={"#third" + index} role="tab" aria-controls={"third" + index} aria-selected="false">Baggage Details</a> </li>
                                                                                <li className="nav-item"> <a className="nav-link" id={"fourth-tab" + index} data-bs-toggle="tab" href={"#fourth" + index} role="tab" aria-controls={"fourth" + index} aria-selected="false">Cancellation Fee</a> </li>
                                                                            </ul>
                                                                            <div className="tab-content my-3" id="departureContent">
                                                                                <div className="tab-pane fade show active" id={"second" + index} role="tabpanel" aria-labelledby="second-tab">
                                                                                    <div className="table-responsive-md">
                                                                                        <table className="table table-hover table-bordered">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th></th>
                                                                                                    <td className="text-center">Base Fare</td>
                                                                                                    <td className="text-center">Net Fare</td>
                                                                                                    <td className="text-center">Tax</td>
                                                                                                    <td className="text-center">Total</td>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td>Adult</td>
                                                                                                    <td className="text-center">{Math.round(flightData.tFPriceDetails.tFAdults.baseFare)}</td>
                                                                                                    <td className="text-center">{Math.round(flightData.tFPriceDetails.tFAdults.netFare)}</td>
                                                                                                    <td className="text-center">{Math.round(flightData.tFPriceDetails.tFAdults.tax)}</td>
                                                                                                    <td className="text-center">{Math.round(flightData.tFPriceDetails.tFAdults.totalFare)}</td>
                                                                                                </tr>
                                                                                                {flightData.tFPriceDetails.tFKids !== null ?
                                                                                                    <tr>
                                                                                                        <td>Child</td>
                                                                                                        <td className="text-center">{Math.round(flightData.tFPriceDetails.tFKids.baseFare)}</td>
                                                                                                        <td className="text-center">{Math.round(flightData.tFPriceDetails.tFKids.netFare)}</td>
                                                                                                        <td className="text-center">{Math.round(flightData.tFPriceDetails.tFKids.tax)}</td>
                                                                                                        <td className="text-center">{Math.round(flightData.tFPriceDetails.tFKids.totalFare)}</td>
                                                                                                    </tr>
                                                                                                    : ""
                                                                                                }
                                                                                                {flightData.tFPriceDetails.tFInfants !== null ?
                                                                                                    <tr>
                                                                                                        <td>Infant</td>
                                                                                                        <td className="text-center">{Math.round(flightData.tFPriceDetails.tFInfants.baseFare)}</td>
                                                                                                        <td className="text-center">{Math.round(flightData.tFPriceDetails.tFInfants.netFare)}</td>
                                                                                                        <td className="text-center">{Math.round(flightData.tFPriceDetails.tFInfants.tax)}</td>
                                                                                                        <td className="text-center">{Math.round(flightData.tFPriceDetails.tFInfants.totalFare)}</td>
                                                                                                    </tr> : ""
                                                                                                }
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="tab-pane fade" id={"third" + index} role="tabpanel" aria-labelledby="third-tab">
                                                                                    <div className="table-responsive-md">
                                                                                        <table className="table table-hover table-bordered">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>&nbsp;</th>
                                                                                                    <td className="text-center">Cabin</td>
                                                                                                    <td className="text-center">Check-In</td>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td>Adult</td>
                                                                                                    <td className="text-center">{flightData.tFPriceDetails.tFAdults.cabinBag}</td>
                                                                                                    <td className="text-center">{flightData.tFPriceDetails.tFAdults.checkinBag}</td>
                                                                                                </tr>
                                                                                                {flightData.tFPriceDetails.tFKids !== null ?
                                                                                                    <tr>
                                                                                                        <td>Child</td>
                                                                                                        <td className="text-center">{flightData.tFPriceDetails.tFKids.cabinBag}</td>
                                                                                                        <td className="text-center">{flightData.tFPriceDetails.tFKids.checkinBag}</td>
                                                                                                    </tr>
                                                                                                    :
                                                                                                    <tr>
                                                                                                        <td>Child</td>
                                                                                                        <td className="text-center">7 Kg</td>
                                                                                                        <td className="text-center">15 Kg</td>
                                                                                                    </tr>
                                                                                                }
                                                                                                {flightData.tFPriceDetails.tFInfants !== null ?
                                                                                                    <tr>
                                                                                                        <td>Infant</td>
                                                                                                        <td className="text-center">{flightData.tFPriceDetails.tFInfants.cabinBag}</td>
                                                                                                        <td className="text-center">{flightData.tFPriceDetails.tFInfants.checkinBag}</td>
                                                                                                    </tr>
                                                                                                    :
                                                                                                    <tr>
                                                                                                        <td>Infant</td>
                                                                                                        <td className="text-center">0 Kg</td>
                                                                                                        <td className="text-center">0 Kg</td>
                                                                                                    </tr>
                                                                                                }
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="tab-pane fade" id={"fourth" + index} role="tabpanel" aria-labelledby="fourth-tab">
                                                                                    <p className="fw-bold">Terms &amp; Conditions</p>
                                                                                    <ul>
                                                                                        <li>The penalty is subject to 4 hrs before departure. No Changes are allowed after that.</li>
                                                                                        <li>The charges are per passenger per sector.</li>
                                                                                        <li>Partial cancellation is not allowed on tickets booked under special discounted fares.</li>
                                                                                        <li>In case of no-show or ticket not cancelled within the stipulated time, only statutory taxes are refundable subject to Service Fee.</li>
                                                                                        <li>No Baggage Allowance for Infants</li>
                                                                                        <li>Airline penalty needs to be reconfirmed prior to any amendments or cancellation.</li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="col-xl-12 col-lg-12 col-md-12">
                                                    {/* Overview */}
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h6 className="fw-semibold mb-0">Traveler Details</h6>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="table-responsive-md">
                                                                <table className="table table-hover table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>SNo.</th>
                                                                            <th>Name</th>
                                                                            <th>Gender</th>
                                                                            <th>Type</th>
                                                                            {params.isDomestic === "INTERNATIONAL" ?
                                                                                <th>DOB</th>
                                                                                :
                                                                                ""}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {travellers.length > 0 && travellers.map((t, index) =>
                                                                            <tr>
                                                                                <td>{index + 1}</td>
                                                                                <td>{t.title}.{t.firstName} {t.lastName}</td>
                                                                                <td>{t.gender}</td>
                                                                                <td>{t.travelerType}</td>
                                                                                {params.isDomestic === "INTERNATIONAL" ?
                                                                                    <td>{moment(t.dateOfBirth).format('DD MMM YYYY')}</td>
                                                                                    :
                                                                                    ""}
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="full-width d-flex flex-column mb-2 position-relative">
                                                                <div className="row align-items-stat">
                                                                    <div className="col-xl-6 col-lg-12 col-md-12">
                                                                    </div>
                                                                    <div className="col-xl-6 col-lg-12 col-md-12">
                                                                        <div className="text-center d-flex align-items-center justify-content-center">
                                                                            <button className="btn fw-medium btn-danger full-width m-2" type="button" onClick={handleFlightSearch}>Back to Fights List</button>
                                                                            <button className="btn fw-medium btn-primary full-width" type="button" onClick={e => handleSubmit(e)}>Make Payment</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-lg-4 col-md-12">
                                            <div className="side-block card rounded-2 p-3">
                                                <div className="d-flex align-items-center justify-content-between mb-3">
                                                    <h5 className="fw-semibold fs-6 mb-0">Payment Summary</h5>
                                                </div>
                                                <div className="card mb-4 mt-lg-0 mt-4">
                                                    <div className="card-body py-2">
                                                        <div className="price-summary">
                                                            <ul className="list-group">
                                                                <li className="list-group-item d-flex justify-content-between align-items-center border-0 py-2 px-0">
                                                                    Base Fare
                                                                    <span className="fw-semibold text-dark">INR {Math.round(totalFare)}</span>
                                                                </li>
                                                                {discount !== 0 ?
                                                                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 py-2 px-0">
                                                                        Discount
                                                                        <span class="fw-semibold text-success">INR {discount}</span>
                                                                    </li>
                                                                    : ""}
                                                                <li className="list-group-item d-flex justify-content-between align-items-center border-0 py-2 px-0">
                                                                    Tax & Service Fee
                                                                    <span class="fw-semibold text-dark">INR {parseInt(convenienceFee + paymentGatewayFee)}</span>
                                                                </li>
                                                                {priceChange !== "" ?
                                                                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 py-2 px-0">
                                                                        Note :
                                                                        <span className="fw-semibold text-success">{priceChange}</span>
                                                                    </li> : ""}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="card-footer bg-white border-top py-3">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <p className="fw-semibold text-muted-2 mb-0">Total Price</p>
                                                            <p className="fw-semibold text-primary mb-0">INR {Math.round(totalPrice)}</p>
                                                        </div>
                                                    </div>
                                                    <div className="card border rounded-3">
                                                        <div className="card-header">
                                                            <h4>Coupons &amp; Offers</h4>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="form-group position-relative">
                                                                <input type="text" class="form-control" placeholder="Have a Coupon Code?" name="couponCode" value={couponCode} onChange={(e) => setCouponCode(e.target.value)}></input>
                                                                <Link className="position-absolute top-50 end-0 fw-semibold translate-middle text-primary disable" onClick={checkCouponCode}>Apply</Link>
                                                                {promoCodeError === true ? <span className="fw-medium text-primary">Invalid coupon code</span> : ""}
                                                            </div>
                                                            <div className="mid-block mb-2">
                                                                <div className="paymntCardsoption-groups">
                                                                    {promocodes.length > 0 && promocodes.map((pc, index) =>
                                                                        <div className="single-paymntCardsoption d-block position-relative mb-2">
                                                                            <div className="paymnt-line active d-flex align-items-center justify-content-start">
                                                                                <div className="position-relative text-center">
                                                                                    <div className="form-check lg mb-0">
                                                                                        <input className="form-check-input" style={{ border: "1px solid" }} type="radio" name="coupons" id="coupons" onClick={e => handleCouponCode(e, pc)} />
                                                                                        <label className="form-check-label" htmlFor="coupons" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="paymnt-line-caps d-flex align-items-center justify-content-start">
                                                                                    <div className="paymnt-caps-details ps-2">
                                                                                        <span className="text-uppercase d-block fw-semibold text-md text-dark lh-2 mb-0">{pc.code}</span>
                                                                                        <span className="text-sm text-muted lh-2">{pc.description}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>}</div>
    )
}