import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import APIConfig from "../Configuration/APIConfig";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import PageLoader from "../Layout/PageLoader";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
const initialFieldValues = {
    studyEnquiryId: "00000000-0000-0000-0000-000000000000",
    studyDestinationId: "00000000-0000-0000-0000-000000000000",
    customerName: "",
    email: "",
    mobile: "",
    status: "NEW",
    studyDate: new Date(),
    comments: ""
};
export default function StudyAboard() {
    const [values, setValues] = useState(initialFieldValues);
    const [btnSubmit, setBtnSubmit] = useState(true);
    const [status, setStatus] = useState("");
    const [errors, setErrors] = useState({});
    const [tours, setTours] = useState([]);
    const [tempTours, setTempTours] = useState([]);
    const [loading, setLoading] = useState(true);
    const [fromDate, setFromDate] = useState(new Date());
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const validate = () => {
        let temp = {};
        temp.customerName = values.customerName === "" ? false : true;
        temp.mobile = values.mobile === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setBtnSubmit(false);
            const formData = new FormData()
            formData.append("studyEnquiryId", "00000000-0000-0000-0000-000000000000")
            formData.append("studyDestinationId", values.studyDestinationId)
            formData.append("customerName", values.customerName)
            formData.append("email", values.email)
            formData.append("mobile", values.mobile)
            formData.append("studyDate", moment(values.studyDate).format('YYYY-MM-DD'))
            formData.append("comments", values.comments)
            addOrEdit(formData);
        }
    };
    const applicationAPI = () => {
        return {
            create: (newrecord) => axios.post(APIConfig.APIACTIVATEURL + APIConfig.CREATESTUDYENQUIRY, newrecord, { ...headerconfig })
        };
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setErrors({});
    };
    const addOrEdit = (formData) => {
        if (formData.get('studyEnquiryId') === "00000000-0000-0000-0000-000000000000") {
            applicationAPI()
                .create(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        setStatus("Enquiry Submitted Successfully");
                        resetForm();
                        setBtnSubmit(true);
                    }
                    else {
                        setStatus("");
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    setStatus("");
                    setBtnSubmit(true);
                });
        }
    }
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    const GetStudies = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETSTUDYDESTINATIONS, { ...headerconfig })
            .then((response) => {
                setTours(response.data.data.data);
                setTempTours(response.data.data.data);
                setLoading(false)
            });
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        GetStudies();
    }, []);
    return (
        <div id="main-wrapper">
            {loading === true ? <PageLoader></PageLoader> :
                <div>
                    <Header></Header>
                    <div className="clearfix"></div>
                    <div className="py-2 bg-primary position-relative">
                    </div>
                    <section className="gray-simple">
                        <div className="container">
                            <div className="row justify-content-between gy-4 gx-xl-4 gx-lg-3 gx-md-3 gx-4">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-xl-4 col-lg-4 col-md-4">
                                            <h5 className="fw-bold fs-6 mb-lg-0 mb-3">Showing {tours.length} Search Results</h5>
                                        </div>
                                        <div className="col-xl-8 col-lg-8 col-md-12">
                                            <div className="d-flex align-items-center justify-content-start justify-content-lg-end flex-wrap">
                                                <div className="flex-first me-2">
                                                    <button className="btn btn-filter btn-dark" type="button" data-bs-toggle="modal" data-bs-target="#login"><i className="fa-solid fa-phone me-2" /><span className="d-none d-md-block">Enquiry Now</span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="row justify-content-center gy-4 gx-xl-4 gx-3">
                                        {tours.length > 0 && tours.map(t =>
                                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                                <div className="pop-touritem">
                                                    <Link href="#" className="card rounded-3 m-0">
                                                        <div className="flight-thumb-wrapper p-2 pb-0">
                                                            <div className="popFlights-item-overHidden rounded-3">
                                                                <img src={t.imageSrc} className="img-fluid" alt width="100%" height="100%" />
                                                            </div>
                                                        </div>
                                                        <div className="touritem-middle position-relative p-3">
                                                            <div className="explot">
                                                                <h4 className="city fs-title m-0 fw-bold">
                                                                    <span>{t.destinationName}</span>
                                                                </h4>
                                                            </div>
                                                            <div className="touritem-flexxer mt-4">
                                                                <div className="tourist-wooks position-relative mb-3">
                                                                    <p>{t.content}</p>
                                                                </div>
                                                            </div>
                                                            <div className="trsms-foots mt-4">
                                                                <div className="flts-flex d-flex align-items-end justify-content-between">
                                                                    <div className="flts-flex-strat">
                                                                        <div className="row align-items-center justify-content-end gx-2">
                                                                            <div className="col-auto">
                                                                                <Link to={"/studydetails/" + t.studyDestinationId} className="btn btn-md btn-success full-width fw-medium px-lg-4">View Details</Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flts-flex-end">
                                                                        <div className="row align-items-center justify-content-end gx-2">
                                                                            <div className="col-auto">
                                                                                <button className="btn btn-md btn-primary full-width fw-medium px-lg-4" type="button" data-bs-toggle="modal" data-bs-target="#login"><i className="fa-solid fa-phone me-2" /><span className="d-none d-md-block">Enquiry Now</span></button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer></Footer>
                </div>
            }
            <div className="modal fade" id="login" tabIndex={-1} role="dialog" aria-labelledby="loginmodal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered login-pop-form" role="document">
                    <div className="modal-content" id="loginmodal">
                        <div className="modal-header">
                            <h4 className="modal-title fs-6">ENQUIRE NOW</h4>
                            <a href="#" className="text-muted fs-4" data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-square-xmark" /></a>
                        </div>
                        <div className="modal-body">
                            {status !== "" ?
                                <div class="alert alert-info shadow-md"> <i class="fas fa-check-circle"></i> {status} </div>
                                : ""}
                            <div className="modal-login-form py-4 px-md-3 px-0">
                                <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                    <div className="form-floating mb-2">
                                        <input type="text" value={values.customerName} name="customerName" onChange={handleInputChange} className={"form-control" + applyErrorClass('customerName')} placeholder="Please enter customer name" />
                                        <label>Name</label>
                                        {errors.customerName === false ? (<div className="validationerror">Please enter customer name </div>) : ('')}
                                    </div>
                                    <div className="form-floating mb-2">
                                        <input type="text" value={values.email} name="email" onChange={handleInputChange} className={"form-control" + applyErrorClass('email')} placeholder="Please enter email" />
                                        <label>Email</label>
                                    </div>
                                    <div className="form-floating mb-2">
                                        <input type="text" value={values.mobile} name="mobile" onChange={handleInputChange} className={"form-control" + applyErrorClass('mobile')} placeholder="Please enter mobile" />
                                        <label>Phone</label>
                                        {errors.mobile === false ? (<div className="validationerror">Please enter mobile </div>) : ('')}
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="comments" className="form-label">Comments</label>
                                        <input type="text" value={values.comments} name="comments" onChange={handleInputChange} className={"form-control" + applyErrorClass('comments')} placeholder="Fees, Scholarships..." />
                                        {errors.notes === false ? (<div className="validationerror">Please enter comments </div>) : ('')}
                                    </div>
                                    <div className="form-group">
                                        <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                            {
                                                btnSubmit === true ?
                                                    <button type="submit" className="btn btn-primary w-100">{values.studyEnquiryId === "00000000-0000-0000-0000-000000000000" ? "Submit" : "Update"}</button>
                                                    :
                                                    <button type="submit" className="btn btn-primary w-100" disabled>Please wait...</button>
                                            }
                                            <button type="button" className="btn btn-danger w-100" onClick={resetForm}>Cancel</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}