import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import APIConfig, { APIACTIVATEURL } from "../Configuration/APIConfig";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import PageLoader from "../Layout/PageLoader";
import "react-datepicker/dist/react-datepicker.css";
const initialValues = {
    transactionId: "00000000-0000-0000-0000-000000000000",
    amount: 0,
    transactionType: "CREDIT",
    bookingType: "HOTEL",
    status: "",
    referenceNo: "",
    paymentGatewayNo: "",
    createdDate: new Date(),
    referenceId: "00000000-0000-0000-0000-000000000000"
};
export default function HotelReview() {
    const { state } = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const [checkIn, setCheckIn] = useState(new Date());
    const [checkOut, setCheckOut] = useState(new Date());
    const [nights, setNights] = useState(1);
    const [searchQuery, setSearchQuery] = useState({});
    const [hotelBookingRequest, setHotelBookingRequest] = useState({});
    const [saveHotel, setSaveHotel] = useState({});
    const [hotelRoom, setHotelRoom] = useState({});
    const [guestRooms, setGuestRooms] = useState([]);
    const [price, setPrice] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);    
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [proceed, setProceed] = useState(true);
    const handleAdultChange = (roomindex, fieldindex, field, value) => {
        const updatedRooms = [...guestRooms];
        if (field == "title") {
            var gender = "MALE";
            if (value === "Mr") {
                gender = "MALE";
            }
            else if (value === "Mrs") {
                gender = "FEMALE";
            }
            else if (value === "Ms") {
                gender = "FEMALE";
            }
            else if (value === "Master") {
                gender = "MALE";
            }
            updatedRooms[roomindex].adultsFields[fieldindex]['gender'] = gender;
        }

        updatedRooms[roomindex].adultsFields[fieldindex][field] = value;
        setGuestRooms(updatedRooms);
    };
    const handleKidChange = (roomindex, fieldindex, field, value) => {
        const updatedRooms = [...guestRooms];
        if (field == "title") {
            var gender = "MALE";
            if (value === "Mr") {
                gender = "MALE";
            }
            else if (value === "Mrs") {
                gender = "FEMALE";
            }
            else if (value === "Ms") {
                gender = "FEMALE";
            }
            else if (value === "Master") {
                gender = "MALE";
            }
            updatedRooms[roomindex].kidsFields[fieldindex]['gender'] = gender;
        }
        updatedRooms[roomindex].kidsFields[fieldindex][field] = value;
        setGuestRooms(updatedRooms);
    };
    function getPaymentInfo() {
        const myobject = [{
            amount: totalPrice
        }];
        return myobject;
    }
    function getDeliveryinfo() {
        const emails = [email];
        const contacts = [mobile];
        const code = ["+91"];
        const deliveryinfo = {
            emails: emails,
            contacts: contacts,
            code: code
        };
        return deliveryinfo;
    }
    const handleEmailChange = (e) => {
        setEmail(e.target.value)
    }
    const handleMobileChange = (e) => {
        setMobile(e.target.value)
    }
    const handleHotelSearch = (e) => {
        navigate('/hotelslist/', { state: { searchQuery } });
    }
    const handleBooking = (e) => {
        e.preventDefault();
        displayRazorpay();
    };
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }
    async function displayRazorpay() {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }
        // creating a new order
        const result = await axios.post(
            APIConfig.APIACTIVATEURL + APIConfig.PAYMENTREQUESTORDER + "?Amount=" + Math.round(totalPrice)
        );

        if (!result) {
            alert("Server error. Are you online?");
            return;
        }
        // Getting the order details back
        if (result.data.response.succeeded) {
            const { amount, id: order_id, currency } = result.data.response.data;

            const options = {
                key: APIConfig.RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
                amount: amount,
                currency: currency,
                name: APIConfig.COMPANYNAME,
                description: "Flight Booking Fee",
                order_id: order_id,
                handler: async function (response) {
                    const data = {
                        orderCreationId: order_id,
                        razorpayPaymentId: response.razorpay_payment_id,
                        razorpayOrderId: response.razorpay_order_id,
                        razorpaySignature: response.razorpay_signature,
                    };
                    initialValues.amount = totalPrice;
                    initialValues.transactionType = "Credit";
                    initialValues.referenceId = "";
                    initialValues.status = "SUCCESS";
                    initialValues.bookingType = "HOTEL";
                    initialValues.paymentGatewayNo = response.razorpay_payment_id;
                    InsertBooking(initialValues, hotelBookingRequest);
                },
                prefill: {
                    name: "",
                    email: APIConfig.COMPANYEMAIL,
                    contact: APIConfig.COMAPNYPHONE,
                },
                notes: {
                    address: APIConfig.COMPANYCITY,
                },
                theme: {
                    color: "#61dafb",
                },
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        }
        else {
            alert('payment issue')
        }
    }
    const InsertBooking = (data, hotelBookingRequest) => {
        setLoading(true)
        hotelBookingRequest.transactionNo = data.paymentGatewayNo;
        hotelBookingRequest.paymentStatus = data.status;
        hotelBookingRequest.paymentGatewayNo = data.paymentGatewayNo;
        setLoading(true)
        axios
            .post(APIConfig.APIACTIVATEURL + APIConfig.HOTELBOOK, hotelBookingRequest, { ...headerconfig })
            .then((response) => {
                var responseData = response.data.response;
                var hotelbookingResponse = responseData.data;
                setLoading(false);
                navigate('/hotelbookingsuccess/', { state: { hotelbookingResponse } });
            }).catch(function (error) {
                setLoading(false);
            });
    };
    useEffect(() => {
        console.log(state)
        if (state === null) {
            navigate('/searchhotels')
        }
        else if (state.hotelBookingRequest === null) {
            navigate('/searchhotels')
        }
        else if (state.hotelBookingRequest.commonHotelSearchRequest === null) {
            navigate('/searchhotels')
        }
        else {
            setLoading(false)
            console.log(state)
            setHotelBookingRequest(state.hotelBookingRequest);
            setSearchQuery(state.hotelBookingRequest.commonHotelSearchRequest)
            setSaveHotel(state.hotelBookingRequest.commonHotelDetails)
            setHotelRoom(state.hotelBookingRequest.commonHotelAllRooms)
            setGuestRooms(state.hotelBookingRequest.commonHotelSearchRequest.hotelRooms)
            setCheckIn(state.hotelBookingRequest.commonHotelSearchRequest.checkInDate)
            setCheckOut(state.hotelBookingRequest.commonHotelSearchRequest.checkOutDate)

            setPrice(state.hotelBookingRequest.commonHotelAllRooms.price);
            setTotalPrice(state.hotelBookingRequest.commonHotelAllRooms.totalPrice)
            const cinDate = new Date(state.hotelBookingRequest.commonHotelSearchRequest.checkInDate);
            const coutDate = new Date(state.hotelBookingRequest.commonHotelSearchRequest.checkOutDate);
            const diffTime = Math.abs(coutDate.getTime() - cinDate.getTime());
            const nights = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            setNights(nights);
        }
    }, []);
    return (
        <div id="main-wrapper">
            {loading === true ? <PageLoader></PageLoader> :
                <div>
                    <Header></Header>
                    <div className="clearfix"></div>
                    <div className="py-3 bg-success position-relative">
                        <div className="container">
                        </div>
                    </div>
                    <section className="pt-3 gray-simple">
                        <div className="container">
                            <div className="row align-items-start">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="div-title d-flex align-items-center mb-3">
                                        <h4>Guests Detail</h4>
                                    </div>
                                    <div className="row align-items-start">
                                        <div className="col-xl-8 col-lg-8 col-md-12">
                                            {guestRooms.length > 0 && guestRooms.map((room, index) =>
                                                <div className="card mb-3 p-4" key={index}>
                                                    <h4>Room {index + 1}</h4>
                                                    {room.adultsFields.length > 0 && room.adultsFields.map((ra, adultIndex) => (
                                                        <>
                                                            <div className="card-header">
                                                                <h4>Adult {adultIndex + 1}</h4>
                                                            </div>
                                                            <div className="card-body" key={adultIndex}>
                                                                <div className="row">
                                                                    <div className="col-xl-2 col-lg-6 col-md-6">
                                                                        <labe>Title</labe>
                                                                        <select className="form-control" name="title" required value={room.adultsFields[adultIndex].title} onChange={(e) => handleAdultChange(index, adultIndex, 'title', e.target.value)}>
                                                                            <option value="Mr">Mr</option>
                                                                            <option value="Mrs">Mrs</option>
                                                                            <option value="Miss">Miss</option>
                                                                            <option value="Master">Master</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-xl-4 col-lg-6 col-md-6">
                                                                        <div className="form-group">
                                                                            <label className="form-label">First Name</label>
                                                                            <input type="text" className="form-control" name="firstName" placeholder="First Name" value={room.adultsFields[adultIndex].firstName} onChange={(e) => handleAdultChange(index, adultIndex, 'firstName', e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-4 col-lg-6 col-md-6">
                                                                        <div className="form-group">
                                                                            <label className="form-label">Last Name</label>
                                                                            <input type="text" className="form-control" name="lastName" placeholder="Last Name" value={room.adultsFields[adultIndex].lastName} onChange={(e) => handleAdultChange(index, adultIndex, 'lastName', e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-2 col-lg-6 col-md-6">
                                                                        <div className="form-group">
                                                                            <label className="form-label">Pan Card</label>
                                                                            <input type="text" className="form-control" name="lastName" placeholder="ABCDE1234F" value={room.adultsFields[adultIndex].panCard} onChange={(e) => handleAdultChange(index, adultIndex, 'panCard', e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ))}
                                                    {room.kidsFields.length > 0 && room.kidsFields.map((_, kidIndex) => (
                                                        <>
                                                            <div className="card-header">
                                                                <h4>Kid {kidIndex + 1}</h4>
                                                            </div>
                                                            <div className="card-body" key={kidIndex}>
                                                                <div className="row">
                                                                    <div className="col-sm-3">
                                                                        <labe>Title</labe>
                                                                        <select className="form-control" name="title" required value={room.kidsFields[kidIndex].title} onChange={(e) => handleKidChange(index, kidIndex, 'title', e.target.value)}>
                                                                            <option value="Miss">Miss</option>
                                                                            <option value="Master">Master</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-xl-4 col-lg-6 col-md-6">
                                                                        <div className="form-group">
                                                                            <label className="form-label">First Name</label>
                                                                            <input type="text" className="form-control" placeholder="First Name" value={room.kidsFields[kidIndex].firstName} onChange={(e) => handleKidChange(index, kidIndex, 'firstName', e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-4 col-lg-6 col-md-6">
                                                                        <div className="form-group">
                                                                            <label className="form-label">Last Name</label>
                                                                            <input type="text" className="form-control" name="lastName" placeholder="Last Name" value={room.kidsFields[kidIndex].lastName} onChange={(e) => handleKidChange(index, kidIndex, 'lastName', e.target.value)} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-12">
                                            <div className="side-block card rounded-2 p-3">
                                                <div className="bott-block d-block mb-3">
                                                    <h5 className="fw-semibold fs-6">Booking Summary</h5>
                                                    <ul className="list-group list-group-borderless">
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="fw-medium mb-0">Hotel Name</span>
                                                            <span className="fw-semibold">{saveHotel.hotelName}</span>
                                                        </li>
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="fw-medium mb-0">Address</span>
                                                            <span className="fw-semibold">{saveHotel.address},<br></br>{saveHotel.cityName},{saveHotel.countryName},{saveHotel.postalCode}</span>
                                                        </li>
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="fw-medium mb-0">Check-In</span>
                                                            <span className="fw-semibold">{moment(checkIn).format('DD-MM-YYYY')}</span>
                                                        </li>
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="fw-medium mb-0">Check-Out</span>
                                                            <span className="fw-semibold">{moment(checkOut).format('DD-MM-YYYY')}</span>
                                                        </li>
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="fw-medium mb-0">Nights</span>
                                                            <span className="fw-semibold">{nights}</span>
                                                        </li>
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="fw-medium mb-0">Total Guests</span>
                                                            <span className="fw-semibold">{searchQuery.totalGuests}</span>
                                                        </li>
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="fw-medium mb-0">Total Rooms</span>
                                                            <span className="fw-semibold">{guestRooms.length}</span>
                                                        </li>
                                                        {hotelRoom.requestedHotels.length > 0 && hotelRoom.requestedHotels.map((rh, index) =>
                                                            <li className="list-group-item d-flex justify-content-between">
                                                                <span className="fw-medium mb-0">Room {index + 1} : </span>
                                                                <span className="fw-semibold">{rh.roomType}</span>
                                                            </li>
                                                        )}
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="fw-medium mb-0">Taxes & Fees</span>
                                                            <span className="fw-semibold">INR {Math.round(hotelRoom.taxAndFee)}</span>
                                                        </li>
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="fw-medium text-success mb-0">Total Price</span>
                                                            <span className="fw-semibold text-success">INR {Math.round(hotelRoom.totalPrice)}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="bott-block">
                                                    {proceed === true ?
                                                        <button className="btn fw-medium btn-primary full-width" type="button" onClick={handleBooking}>Make Payment</button>
                                                        :
                                                        <button className="btn fw-medium btn-danger full-width" type="button" onClick={handleHotelSearch}>Back to hotel search</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer></Footer>
                </div>
            }
        </div>
    )
}