import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import APIConfig, { APIACTIVATEURL } from "../Configuration/APIConfig";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import hoteldata from '../Data/HotelDetails.json';
import PageLoader from "../Layout/PageLoader";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export default function HotelDetails() {
    const { state } = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [hotelData, setHotelData] = useState({});
    const [hotelRooms, setHotelRooms] = useState([]);
    const [description, setDescription] = useState([]);
    const [terms, setTerms] = useState([]);
    const [roomDescription, setRoomDescription] = useState([]);
    useEffect(() => {
        setLoading(false)
        console.log(state)
        setHotelData(hoteldata.data.data.commonHotelDetails)
        setHotelRooms(hoteldata.data.data.commonHotelDetails.commonHotelRooms)
        if (hoteldata.data.data.commonHotelDetails.description !== null) {
            const parsedData = JSON.parse(hoteldata.data.data.commonHotelDetails.description);
            setDescription(Object.entries(parsedData).map(([key, value]) => ({ key, value })));
        }
        if (hoteldata.data.data.commonHotelDetails.hotelTerms !== null) {
            setTerms(hoteldata.data.data.commonHotelDetails.hotelTerms);
        }
        if (state === null) {
            navigate('/searchhotels')
        }
        else if (state.searchQuery === null) {
            navigate('/searchhotels')
        }
        else {

        }
    }, []);
    return (
        <div id="main-wrapper">
            {loading === true ? <PageLoader></PageLoader> :
                <div>
                    <Header></Header>
                    <div className="clearfix"></div>
                    <div className="py-3 bg-success position-relative">
                        <div className="container">
                        </div>
                    </div>
                    <section className="pt-3 gray-simple">
                        <div className="container">
                            <div className="row">
                                {/* Breadcrumb */}
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to={"/searchhotels"} className="text-primary">Home</Link></li>
                                            <li className="breadcrumb-item"><Link to={"/searchhotels"} className="text-primary">{hotelData.cityName}, {hotelData.countryName}</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">{hotelData.hotelName}</li>
                                        </ol>
                                    </nav>
                                </div>
                                {/* Gallery & Info */}
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="card border-0 p-3 mb-4">
                                        <div className="crd-heaader d-md-flex align-items-center justify-content-between mb-3">
                                            <div className="crd-heaader-first">
                                                <div className="d-inline-flex align-items-center mb-1">
                                                    <span className="label bg-light-success text-success">{hotelData.propertyType}</span>
                                                    <div className="d-inline-block ms-2">
                                                        <i className="fa fa-star text-warning text-xs" />
                                                        <i className="fa fa-star text-warning text-xs" />
                                                        <i className="fa fa-star text-warning text-xs" />
                                                        <i className="fa fa-star text-warning text-xs" />
                                                        <i className="fa fa-star text-warning text-xs" />
                                                    </div>
                                                </div>
                                                <div className="d-block">
                                                    <h4 className="mb-0">{hotelData.hotelName}</h4>
                                                    <div className>
                                                        <p className="text-md m-0"><i className="fa-solid fa-location-dot me-2" />{hotelData.address}, {hotelData.cityName}, {hotelData.countryName}, {hotelData.postalCode} <a href="#" className="text-primary fw-medium ms-2">Show on Map</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="crd-heaader-last my-md-0 my-2">
                                                <div className="drix-wrap d-flex flex-column align-items-md-end align-items-start text-end">
                                                    <div className="drix-first d-flex align-items-center text-end mb-2">
                                                        <a href="#" className="bg-light-info text-info rounded-1 fw-medium text-sm px-3 py-2 lh-base"><i className="fa-solid fa-bookmark me-2" />Bookmark</a>
                                                        <a href="#" className="bg-light-danger text-danger rounded-1 fw-medium text-sm px-3 py-2 lh-base ms-2"><i className="fa-solid fa-share-nodes me-2" />Share</a>
                                                    </div>
                                                    <div className="drix-last">
                                                        <span className="label bg-light-success text-success">Free Cancellation Till 10 Aug 23
                                                            12:10AM
                                                        </span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="crd-body">
                                            <div className="row align-items-center justify-content-between">
                                                <div className="col-xl-8 col-lg-7 col-md-12">
                                                    <div className="galleryGrid typeGrid_2 mb-lg-0 mb-3">
                                                        <div className="galleryGrid__item relative d-flex">
                                                            <a href="https://placehold.co/1200x800" data-lightbox="roadtrip"><img src="https://placehold.co/1200x800" alt="image" className="rounded-2 img-fluid" /></a>
                                                        </div>
                                                        <div className="galleryGrid__item position-relative">
                                                            <a href="https://placehold.co/1200x800" data-lightbox="roadtrip"><img src="https://placehold.co/1200x800" alt="image" className="rounded-2 img-fluid" /></a>
                                                            <div className="position-absolute end-0 bottom-0 mb-3 me-3">
                                                                <a href="https://placehold.co/1200x800" data-lightbox="roadtrip" className="btn btn-md btn-whites fw-medium text-dark"><i className="fa-solid fa-caret-right me-1" />16 More Photos</a>
                                                            </div>
                                                        </div>
                                                        <div className="galleryGrid__item">
                                                            <a href="https://placehold.co/1200x800" data-lightbox="roadtrip"><img src="https://placehold.co/1200x800" alt="image" className="rounded-2 img-fluid" /></a>
                                                        </div>
                                                        <div className="galleryGrid__item">
                                                            <a href="https://placehold.co/1200x800" data-lightbox="roadtrip"><img src="https://placehold.co/1200x800" alt="image" className="rounded-2 img-fluid" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 col-lg-5 col-md-12">
                                                    <div className="card border br-dashed">
                                                        <div className="card-header">
                                                            <div className="crd-heady102 d-flex align-items-center justify-content-start">
                                                                <div className="square--30 circle bg-light-primary text-primary flex-shrink-0"><i className="fa-solid fa-percent" /></div>
                                                                <div className="crd-heady102Title lh-1 ps-2"><span className="text-sm fw-semibold text-dark text-uppercase lh-1 mb-0">Login not to get upto 20%
                                                                    lower price</span></div>
                                                            </div>
                                                            <div className="crd-heady103">
                                                                <a href="#" className="btn btn-primary btn-sm px-4 fw-semibold text-uppercase">Login</a>
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="d-block mb-3">
                                                                <div className="d-flex align-items-center justify-content-start">
                                                                    <div className="text-dark fw-bold fs-3 me-2">$2999</div>
                                                                    <div className="text-muted-2 fw-medium text-decoration-line-through me-2">US$5000</div>
                                                                    <div className="text-warning fw-semibold">40% Off</div>
                                                                </div>
                                                                <div className="d-flex align-items-start justify-content-start">
                                                                    <div className="text-muted-2 text-md">inclusive of all taxes</div>
                                                                </div>
                                                            </div>
                                                            <div className="d-block">
                                                                <div className="form-group mb-4">
                                                                    <input type="text" className="form-control fw-bold" placeholder="Check-In & Check-Out" id="checkinout" readOnly="readonly" />
                                                                </div>
                                                                <div className="form-group mb-4">
                                                                    <div className="booking-form__input guests-input mixer-auto">
                                                                        <button name="guests-btn" id="guests-input-btn">1 Guest</button>
                                                                        <div className="guests-input__options" id="guests-input-options">
                                                                            <div>
                                                                                <span className="guests-input__ctrl minus" id="adults-subs-btn"><i className="fa-solid fa-minus" /></span>
                                                                                <span className="guests-input__value"><span id="guests-count-adults">1</span>Adults</span>
                                                                                <span className="guests-input__ctrl plus" id="adults-add-btn"><i className="fa-solid fa-plus" /></span>
                                                                            </div>
                                                                            <div>
                                                                                <span className="guests-input__ctrl minus" id="children-subs-btn"><i className="fa-solid fa-minus" /></span>
                                                                                <span className="guests-input__value"><span id="guests-count-children">0</span>Children</span>
                                                                                <span className="guests-input__ctrl plus" id="children-add-btn"><i className="fa-solid fa-plus" /></span>
                                                                            </div>
                                                                            <div>
                                                                                <span className="guests-input__ctrl minus" id="room-subs-btn"><i className="fa-solid fa-minus" /></span>
                                                                                <span className="guests-input__value"><span id="guests-count-room">0</span>Rooms</span>
                                                                                <span className="guests-input__ctrl plus" id="room-add-btn"><i className="fa-solid fa-plus" /></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group mb-4">
                                                                    <div className="d-flex align-items-center justify-content-between">
                                                                        <div className="crd-heady102 d-flex align-items-center justify-content-start">
                                                                            <div className="square--30 circle bg-light-success text-success"><i className="fa-solid fa-percent" /></div>
                                                                            <div className="crd-heady102Title lh-1 ps-2"><span className="text-sm fw-semibold text-dark text-uppercase lh-1 mb-0">oyofestive40 coupon
                                                                                applied</span></div>
                                                                        </div>
                                                                        <div className="crd-heady103">
                                                                            <span className="text-dark fw-semibold text-uppercase">-$1780</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group mb-2">
                                                                    <button type="button" className="btn btn-primary full-width fw-medium">Check
                                                                        Availabilty</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-footer bg-white">
                                                            <div className="row align-items-center justify-content-start gx-2">
                                                                <div className="col-auto">
                                                                    <div className="square--40 rounded-2 bg-seegreen text-light">4.8</div>
                                                                </div>
                                                                <div className="col-auto text-start">
                                                                    <div className="text-md text-dark fw-medium">Exceptional</div>
                                                                    <div className="text-md text-muted-2">3,014 reviews</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Rooms Details */}
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    {hotelRooms.length && hotelRooms.map(hr =>
                                        <div className="card mb-4">
                                            <div className="card-header">
                                                <h6 className="fw-semibold mb-0">{hr.roomCategory}</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="row align-items-start">
                                                    <div className="col-xl-3 col-lg-4 col-md-4">
                                                        <div className="roomavls-groups">
                                                            <div className="roomavls-thumb mb-2">
                                                                <img src={hr.commonImages[0].imageName} className="img-fluid rounded-2" alt />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-9 col-lg-8 col-md-8">
                                                        <div className="d-block border br-dashed rounded-2 px-3 py-3 mb-3">
                                                            <div className="d-flex align-items-sm-end justify-content-between flex-sm-row flex-column">
                                                                <div className="typsofrooms-wrap">
                                                                    <div className="d-flex align-items-center">
                                                                        <h6 className="fs-6 fw-semibold mb-1 me-2">Your Choice</h6><a href="#" className="text-muted fs-6"><i className="fa-solid fa-circle-question" /></a>
                                                                    </div>
                                                                    <div className="typsofrooms-groups d-flex align-items-start">
                                                                        <div className="typsofrooms-brk1 mb-4">
                                                                            <ul className="row align-items-center g-1 mb-0 p-0">
                                                                                <li className="col-12"><span className="text-muted-2 text-md"><i className="fa-solid fa-mug-saucer me-2" />{hr.mealType}</span>
                                                                                </li>
                                                                                <li className="col-12"><span className="text-muted-2 text-md"><i className="fa-solid fa-ban-smoking me-2" />Non-Refundable</span></li>
                                                                                <li className="col-12"><span className="text-success text-md"><i className="fa-solid fa-meteor me-2" />Instant Confirmation</span></li>
                                                                                <li className="col-12"><span className="text-muted-2 text-md"><i className="fa-brands fa-cc-visa me-2" />Prepay Online</span></li>
                                                                                <li className="col-12"><span className="text-success text-md"><i className="fa-solid fa-circle-check me-2" />Booking of Maximum 5 Rooms</span></li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="typsofrooms-action col-auto">
                                                                    <div className="prcrounce-groups">
                                                                        <div className="d-flex align-items-center justify-content-start justify-content-sm-end">
                                                                            <div className="text-dark fw-semibold fs-4">US$ 99</div>
                                                                        </div>
                                                                        <div className="d-flex align-items-start align-items-sm-end justify-content-start justify-content-md-end flex-column mb-2">
                                                                            <div className="text-muted-2 text-sm">After tax US$ 102</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="prcrounce-groups-button d-flex flex-column align-items-start align-items-md-end mt-3">
                                                                        <div className="prcrounce-sngbuttons d-flex mb-2"><button className="btn btn-sm btn-light-primary rounded-1 fw-medium px-4">Book at This
                                                                            Price</button></div>
                                                                        <div className="prcrounce-sngbuttons d-flex"><button className="btn btn-sm btn-primary rounded-1 fw-medium px-4">Access Lower Price</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {/* Top Attractions */}
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="row align-items-center justify-content-between gx-4">
                                        <div className="col-xl-12 col-lg-4 col-md-4">
                                            <div className="card p-3 mb-4">
                                                <div className="nearestServ-wrap">
                                                    <div className="nearestServ-head d-flex mb-1">
                                                        <h6 className="fs-6 fw-semibold text-primary mb-1"><i className="fa-brands fa-servicestack me-2" />Description</h6>
                                                    </div>
                                                    <div className="nearestServ-caps">
                                                        <ul className="row align-items-start g-2 p-0 m-0">
                                                            {description.length > 0 && description.map((d, index) =>
                                                                <li className="col-12 text-muted-2" key={index}><strong>{d.key} : </strong>{d.value}</li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Guests Reviews */}
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="card mb-4">
                                        <div className="card-header">
                                            <h4 className="fs-5 mb-0">Hotel Terms and Conditions</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="row align-items-center">
                                                <div className="col-xl-12 col-lg-12 col-md-12">
                                                    <div className="gstRevws-groups">
                                                        {terms.length && terms.map(t=>
                                                        <div className="single-gstRevws rounded-2 border p-2 d-flex align-items-start mb-3">
                                                            <div className="single-gstRevws-thumb">
                                                                <div className="rounded-2 overflow-hidden w-25 h-25">
                                                                    <img src="https://placehold.co/500x500" className="img-fluid" alt />
                                                                </div>
                                                            </div>
                                                            <div className="single-gstRevws-caps ps-3">
                                                                <div className="gstRevws-head d-flex align-items-start justify-content-between">
                                                                    <div className="dfls-headers">
                                                                        <h5 className="h6 text-dark fw-semibold mb-0">{t.title}</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="dfls-secription">
                                                                    <p className="mb-0">{JSON.stringify(JSON.parse(t.description))}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* FAQ */}
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="row align-items-start justify-content-between gx-3">
                                        <div className="col-xl-3 col-lg-4 col-md-4">
                                            <div className="position-relative mb-4">
                                                <h4 className="lh-base">FAQ Regarding The Royal Plaza Scout</h4>
                                            </div>
                                            <div className="position-relative mb-4">
                                                <button className="btn btn-md btn-primary fw-medium" type="button">Submit Request</button>
                                            </div>
                                        </div>
                                        <div className="col-xl-9 col-lg-8 col-md-8">
                                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                            How To Book A resort with Booer.com?
                                                        </button>
                                                    </h2>
                                                    <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">In a professional context it often happens that private or corporate
                                                            clients corder a publication to be made and presented with the actual content still not being
                                                            ready. Think of a news blog that's filled with content hourly on the day of going live. However,
                                                            reviewers tend to be distracted by comprehensible content, say, a random text copied from a
                                                            newspaper or the internet. The are likely to focus on the text, disregarding the layout and its
                                                            elements.</div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                            Can We Pay After Check-out?
                                                        </button>
                                                    </h2>
                                                    <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">In a professional context it often happens that private or corporate
                                                            clients corder a publication to be made and presented with the actual content still not being
                                                            ready. Think of a news blog that's filled with content hourly on the day of going live. However,
                                                            reviewers tend to be distracted by comprehensible content, say, a random text copied from a
                                                            newspaper or the internet. The are likely to focus on the text, disregarding the layout and its
                                                            elements.</div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                            Is This Collaborate with Oyo?
                                                        </button>
                                                    </h2>
                                                    <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">In a professional context it often happens that private or corporate
                                                            clients corder a publication to be made and presented with the actual content still not being
                                                            ready. Think of a news blog that's filled with content hourly on the day of going live. However,
                                                            reviewers tend to be distracted by comprehensible content, say, a random text copied from a
                                                            newspaper or the internet. The are likely to focus on the text, disregarding the layout and its
                                                            elements.</div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                                            Can We get Any Transport For Walk?
                                                        </button>
                                                    </h2>
                                                    <div id="flush-collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">In a professional context it often happens that private or corporate
                                                            clients corder a publication to be made and presented with the actual content still not being
                                                            ready. Think of a news blog that's filled with content hourly on the day of going live. However,
                                                            reviewers tend to be distracted by comprehensible content, say, a random text copied from a
                                                            newspaper or the internet. The are likely to focus on the text, disregarding the layout and its
                                                            elements.</div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                                            Can We Get Any Extra Services?
                                                        </button>
                                                    </h2>
                                                    <div id="flush-collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                                        <div className="accordion-body">In a professional context it often happens that private or corporate
                                                            clients corder a publication to be made and presented with the actual content still not being
                                                            ready. Think of a news blog that's filled with content hourly on the day of going live. However,
                                                            reviewers tend to be distracted by comprehensible content, say, a random text copied from a
                                                            newspaper or the internet. The are likely to focus on the text, disregarding the layout and its
                                                            elements.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer></Footer>
                </div>
            }
        </div>
    )
}